import "vue-toastification/dist/index.css";
import {POSITION, useToast} from "vue-toastification";
import {Severity} from "./severity";
import {EndpointResponseError} from "./api-utils/types";

const toast = useToast();

const globalOptions = {
  position: POSITION.TOP_CENTER,
  timeout: 1500,
  icon: false,
  closeButton: false,
  hideProgressBar: true,
  toastClassName: "toast-customization",
  maxToasts: 3,
  newestOnTop: true,
  transition: "Vue-Toastification__bounce"
};

function showToast(error: EndpointResponseError) {
  if (error!.severity === Severity.Success) {
    return toast.success(error!.message);
  }

  if (error!.severity === Severity.Info) {
    return toast.info(error!.message);
  }

  return toast.error(error!.message);
}

function error(messageText: string) {
  toast.error(messageText);
}

function warning(messageText: string) {
  toast.warning(messageText);
}


function success(messageText: string) {
  toast.success(messageText);
}

function info(messageText: string) {
  toast.info(messageText);
}

export default {
  options: globalOptions,
  error: error,
  warning: warning,
  success: success,
  info: info,
  showToast: showToast
};
