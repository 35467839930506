<script setup lang="ts">
import {useRouter} from "vue-router";
import Footer from "./Footer.vue";
import LogoImage from "./LogoImage.vue";

const router = useRouter();

</script>

<template>
  <div class="registration">
    <div class="container">
      <div class="header">
        <RouterLink to="/">
         <logo-image/>
        </RouterLink>
      </div>

      <slot/>
    </div>

    <div class="push"></div>
  </div>

  <Footer></Footer>
</template>

<style scoped>
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>

