import {Mask} from "maska";

type FormattedPhoneNumber = {
  code: string,
  maskedValue: string
}

export function normalizePhoneNumber(code?: string, number?: string) {
  let phoneNumber = `${code}${number ? number : ""}`;

  if (!phoneNumber) {
    return phoneNumber;
  }

  phoneNumber = phoneNumber.replace(/\D/g, "");

  return phoneNumber;
}

const kgMask = new Mask({mask: "(###) ##-##-##"});
const kzMask = new Mask({mask: "(###) ###-##-##"});

export function formatPhoneNumber(phoneNumber: string | undefined): FormattedPhoneNumber | null {
  if (!phoneNumber) {
    return null;
  }

  if (phoneNumber.startsWith("996")) {
    phoneNumber = phoneNumber.substring(3);

    return {
      code: "996",
      maskedValue: kgMask.masked(phoneNumber)
    };
  }

  if (phoneNumber.startsWith("7")) {
    phoneNumber = phoneNumber.substring(1);

    return {
      code: "7",
      maskedValue: kzMask.masked(phoneNumber)
    };
  }

  return null;
}