<script setup lang="ts">
import {ref, watch} from "vue";

const props = withDefaults(defineProps<{
  id: string,
  label: string,
  modelValue: string | null | undefined,
  note?: string,
  errorMessage?: string,
  required?: boolean,
  disabled?: boolean,
  placeholder?: string,
  autofocus?: boolean
}>(), {
  note: undefined,
  errorMessage: undefined,
  required: false,
  disabled: false
});

const emit = defineEmits(["update:modelValue"]);

const showPass = ref(false);
const inputValue = ref(props.modelValue);

const isRequired = [
  (val: string | undefined) => (val && val.length > 0) || 'Заполните поле'
];

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue);
});

</script>

<template>
  <div class="q-my-sm q-mr-sm">
    <q-input
        v-model="inputValue"
        :type="showPass ? 'text' : 'password'"
        color="secondary"
        :disable="props.disabled"
        :autofocus="props.autofocus"
        :label="props.label"
        :error="!!props.errorMessage"
        :error-message="errorMessage"
        hide-bottom-space
        
        :rules="isRequired"
    >

      <template v-slot:append>
        <q-icon
            :name="showPass ? 'visibility' : 'visibility_off'"
            @click="showPass = !showPass"
            class="cursor-pointer"
        />
      </template>
    </q-input>

    <div v-if="props.note" class="text">{{ props.note }}</div>
  </div>
</template>