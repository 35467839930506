<script setup lang="ts">
import {ref, watch} from "vue";
import {formatPhoneNumber, normalizePhoneNumber} from "@lib/utils";

const props = withDefaults(defineProps<{
  id: string,
  label: string,
  modelValue: string | null | undefined,
  errorMessage?: string,
  disabled?: boolean,
  required?: boolean,
  autofocus?: boolean
}>(), {
  errorMessage: undefined,
  disabled: false,
  required: false,
  autofocus: false
});

type PhoneCodeType = "996" | "7"

const phoneCodeOptions: { label: string, value: PhoneCodeType }[] = [
  {label: "+996", value: "996"},
  {label: "+7", value: "7"}
];

const maska: Record<PhoneCodeType, string> = {
  "996": "(###) ##-##-##",
  "7": "(###) ###-##-##"
}

const emit = defineEmits(["update:modelValue"]);

const code = ref(phoneCodeOptions[0]);
const phoneNumber = ref("");

const isRequired = [
  (value: string | undefined, rules: Record<string, unknown>): string | boolean => {
    const expectedCount = maska[code.value.value].match(/#/g)?.length;
    const actualCount = (value ?? "").replace(/\D/g, "").length;
    
    if (expectedCount !== actualCount) {
      return `Заполните поле`;
    }
    
    return true;
  }
];

watch(phoneNumber, () => {
  emit("update:modelValue", normalizePhoneNumber(code.value.value, phoneNumber.value))
});

watch(() => props.modelValue, () => {
  const formattedPhoneNumber = formatPhoneNumber(props.modelValue ?? undefined);
  if (!formattedPhoneNumber) {
    return
  }

  phoneNumber.value = formattedPhoneNumber.maskedValue
}, {
  immediate: true
})
</script>

<template>
  <div class="row q-col-gutter-sm q-my-sm q-mr-sm">
    <div class="col-3">
      <q-select
          v-model="code"
          :options="phoneCodeOptions"
          :disable="props.disabled"
          label="Код"
          color="secondary"
          hide-bottom-space
          hide-dropdown-icon
      />
    </div>

    <div class="col-9">
      <q-input
          :id="props.id"
          v-model="phoneNumber"
          label="Номер телефона"
          :mask="maska[code.value]"
          :disable="props.disabled"
          color="secondary"
          :error="!!props.errorMessage"
          :error-message="props.errorMessage"
          hide-bottom-space
          name="my-input-123" autocomplete="off"
          :rules="isRequired"
          lazy-rules
          type="tel"
          :autofocus="props.autofocus"
      />
    </div>
  </div>
</template>

