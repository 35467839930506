<script setup lang="ts">
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import passwordRecoveryApi from "../../api/password-recovery-api";
import {toasts} from "@lib/utils";
import {PasswordField} from "@lib/components";

const router = useRouter();
const props = defineProps(["requestId"]);
const emits = defineEmits(["next", "requestIdNotSpecified"]);

if (!props.requestId) {
  emits("requestIdNotSpecified");
}

let newPassword = ref("");
let confirmNewPassword = ref("");
const parameterErrors = ref<Record<string, string> | undefined>();
const isBtnDisable = ref();

const confirmNewPasswordError = computed(() => {
  const password = newPassword.value
  const confirmPassword = confirmNewPassword.value

  if (!password) {
    return undefined;
  }

  if (!confirmPassword && parameterErrors.value?.confirmNewPassword) {
    return parameterErrors.value.confirmNewPassword;
  }

  if (password !== confirmPassword) {
    return "Пароли не совпадают";
  }

  return undefined;
});

const passwordError = computed(() => {
  const password = newPassword.value;

  if (!password && parameterErrors.value?.newPassword) {
    return parameterErrors.value.newPassword;
  }

  if (password?.length !<= 5 && parameterErrors.value?.newPassword) {
    return parameterErrors.value.newPassword;
  }

  return undefined;
});

async function updatePassword() {
  isBtnDisable.value = true;

  const {error} = await passwordRecoveryApi.updatePassword(props.requestId, newPassword.value, confirmNewPassword.value);

  isBtnDisable.value = false;

  if (error) {
    if (error.message) {
      toasts.showToast(error);
    }

    parameterErrors.value = error.parameterErrors;
    return;
  }

  parameterErrors.value = undefined;
  emits("next");
}
</script>

<template>
  <div class="registration-form">
    <h4 class="content-title">Смена пароля</h4>

    <form @submit.prevent="updatePassword">
      <password-field
          v-model="newPassword"
          :error-message="passwordError"
          label="Новый пароль"
          :id="newPassword"
      />
      <password-field
          v-model="confirmNewPassword"
          :error-message="confirmNewPasswordError"
          label="Подтвердите пароль"
          :id="confirmNewPassword"
      />

      <q-btn
          type="submit"
          label="Поменять пароль"
          :disabled="isBtnDisable"
          color="primary"
          class="q-ma-md"/>

      <div>
        <a href="" @click.prevent="router.go(-1)">Помните пароль?</a>
      </div>

      <q-btn
          label="Зарегистрироваться"
          href="https://www.w1.kz/user-registration" target="_blank"
          color="red"
          outline
          class="q-ma-md"
      />
    </form>
  </div>
</template>

