<script setup lang="ts">
import {SiteNotification} from "@lib/types";

const props = defineProps<{
  notifications: SiteNotification[]
}>();
</script>

<template>
  <div v-for="notification in props.notifications" class="layoutNotification">

    <div v-if="notification.severity === 'error'">
      <div class="error-notification fz16" v-html="notification.text">
      </div>
    </div>

    <div v-if="notification.severity === 'warning'">
      <div class="warning-notification fz16">
        <div v-html="notification.text"></div>
      </div>
    </div>

    <div v-if="notification.severity === 'info'">
      <div class="info-notification fz16">
        <div v-html="notification.text"></div>
      </div>
    </div>

  </div>
</template>
