<script setup lang="ts">
import {ref} from "vue";

import Start from "./Start.vue";
import ConfirmCode from "./ConfirmCode.vue";
import UpdatePassword from "./UpdatePassword.vue";
import steps from "./steps";
import {useRouter} from "vue-router";
import {toasts} from "@lib/utils";

const router = useRouter();
const step = ref(steps.START);
const requestId = ref<number | null>(null);

function onStartStepFinish(payload: { requestId: number }) {
  requestId.value = payload.requestId;
  step.value = steps.CONFIRM_CODE;
}

function onConfirmCodeStepFinish() {
  step.value = steps.UPDATE_PASSWORD;
}

async function onUpdatePasswordStepFinish() {
  toasts.success("Ваш пароль успешно восстановлен");
  await router.go(-1);
}

function onRequestIdNotSpecified() {
  toasts.error("Запрос на восстановление пароля не найден");
  step.value = steps.START;
}
</script>

<template>
  <Start v-if="step === steps.START" @next="onStartStepFinish"/>

  <ConfirmCode
      v-if="step === steps.CONFIRM_CODE"
      :request-id="requestId"
      @next="onConfirmCodeStepFinish"
      @requestIdNotSpecified="onRequestIdNotSpecified"
  />

  <UpdatePassword
      v-if="step === steps.UPDATE_PASSWORD"
      :request-id="requestId"
      @next="onUpdatePasswordStepFinish"
  />

</template>