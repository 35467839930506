<script setup lang="ts">
import {ref} from "vue";
import {useRouter} from "vue-router";

import {PhoneNumberField, ReCaptcha} from "@lib/components";
import {normalizeLogin, toasts} from "@lib/utils";
import passwordRecoveryApi from "../../api/password-recovery-api";

const router = useRouter();
const emits = defineEmits(["next"]);

const login = ref("");
const code = ref("");
const reCaptchaToken = ref<string | null>(null);
const isBtnDisable = ref();

const parameterErrors = ref<Record<string, string> | undefined>({});

async function handleSubmit() {
  isBtnDisable.value = true;

  const {error, result} = await passwordRecoveryApi.start(normalizeLogin(login.value), reCaptchaToken.value!);

  isBtnDisable.value = false;

  if (error) {
    if (error.message) {
      toasts.showToast(error);
    }

    parameterErrors.value = error.parameterErrors;
    return;
  }

  parameterErrors.value = undefined;

  emits("next", {
    requestId: result.passwordRecoveryRequestId
  });
}

function onCaptchaVerified(token: string) {
  reCaptchaToken.value = token;

  parameterErrors.value!.reCaptchaToken = "";
}

function onReCaptchaExpired() {
  reCaptchaToken.value = null;
}
</script>

<template>
  <div class="registration-form">
    <h4 class="content-title">Восстановление доступа</h4>

    <form @submit.prevent="handleSubmit">
      <PhoneNumberField
          v-model="login"
          :error-message="parameterErrors?.login"
          label="Номер телефона"
          :id="login"
      />

      <div class="flex justify-center q-mt-md">
        <ReCaptcha
            :on-captcha-verified="onCaptchaVerified"
            :on-captcha-expired="onReCaptchaExpired"
            :error-message="parameterErrors?.reCaptchaToken"
        />
      </div>

      <div class="q-pa-md">
        <q-btn
            label="Восстановить"
            :disabled="isBtnDisable"
            color="primary"
            size="md"
            type="submit"
        />
      </div>

      <div>
        <a href="" @click.prevent="router.push({name:'pay'})">Помните пароль?</a>
      </div>

      <div class="q-pa-md">
        <q-btn
            label="Зарегистрироваться"
            href="https://www.w1.kz/user-registration" target="_blank"
            color="red"
            outline
        />
      </div>
    </form>
  </div>
</template>

