import {Dark} from "quasar";

const localStorageKey = "is-dark-mode";

function getCurrentTheme(): boolean {
  let localStorageValue: boolean = JSON.parse(localStorage.getItem(localStorageKey)!);

  if (localStorageValue === null) {
    localStorageValue = getMediaPreference();
  }

  return localStorageValue;
}

function setCurrentTheme(isDarkMode: boolean) {
  Dark.set(isDarkMode);
  localStorage.setItem(localStorageKey, JSON.stringify(isDarkMode));
}

function isDark() {
 return  Dark.isActive;
}

function getMediaPreference(): boolean {
  return window.matchMedia("(prefers-color-scheme: dark)").matches;

}

export default {
  getCurrent: getCurrentTheme,
  setCurrent: setCurrentTheme,
  isDark
};