<script setup lang="ts">
import {onMounted, onUnmounted, ref, watch} from "vue";
import {siteThemeHelper} from "@lib/utils";
import {UnauthorizedPageLayout} from "@lib/components";
import {useQuasar} from "quasar";
import buildVersionApi from "./api/build-version-api";
import {buildVersion} from "./version";

const $q = useQuasar();

const retrievedBuildVersion = ref<{ buildVersion: string } | null>(null);

let siteUpdateTimer: ReturnType<typeof setInterval>;

onMounted(async () => {
  siteThemeHelper.setCurrent(siteThemeHelper.getCurrent());

  await startCheckBuildVersion();
});

async function startCheckBuildVersion() {
  const {error, result} = await buildVersionApi.getBuildVersion();

  if (error) {
    console.error(error);
    return;
  }

  retrievedBuildVersion.value = result;

  siteUpdateTimer = setInterval(async () => {
    const {error, result} = await buildVersionApi.getBuildVersion();

    if (error) {
      console.error(error);
      return;
    }

    retrievedBuildVersion.value = result;
  }, 10000);
}

onUnmounted(() => {
  clearInterval(siteUpdateTimer);
})

function refreshSite() {
  location.reload();
}

function notifySiteUpdate() {
  $q.notify({
    message: `Для корректной работы сайта необходимо обновить страницу!`,
    color: 'orange-5',
    position: 'top',
    timeout: 0,
    classes: "site-update-notify",
    type: "info",
    badgeStyle: {display: "none"},
    attrs: {
      role: "alert"
    },
    actions: [
      {
        label: 'Обновить',
        color: "grey-11",
        handler: () => refreshSite()
      },
    ]
  })
}

watch(retrievedBuildVersion, () => {
  if (retrievedBuildVersion.value && retrievedBuildVersion.value.buildVersion !== buildVersion.buildVersion) {
    notifySiteUpdate();
  }
})

</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | W1` : `W1` }}</template>
  </metainfo>

  <unauthorized-page-layout>
    <router-view/>
  </unauthorized-page-layout>

</template>

<style lang="scss">
@import "../../../assets/scss/main";
</style>

