import {httpPost} from "@lib/utils";

async function start(login: string, reCaptchaToken: string) {
  return await httpPost<{ passwordRecoveryRequestId: number }>("/api/customer/invoice/password-recovery/v1.0/start", {
    login: login,
    reCaptchaToken: reCaptchaToken
  });
}

async function confirmCode(passwordRecoveryRequestId: number, code: string) {
  return await httpPost("/api/customer/invoice/password-recovery/v1.0/confirm-code", {
    passwordRecoveryRequestId,
    secretCode: code
  });
}

async function updatePassword(passwordRecoveryRequestId: number, newPassword: string, confirmNewPassword: string) {
  return await httpPost("/api/customer/invoice/password-recovery/v1.0/update-password", {
    passwordRecoveryRequestId,
    newPassword,
    confirmNewPassword
  });
}

async function sendCodeAgain(passwordRecoveryRequestId: string, reCaptchaToken: string) {
  return await httpPost("/api/customer/invoice/password-recovery/v1.0/send-code-again", {
    passwordRecoveryRequestId,
    reCaptchaToken
  });
}

export default {
  start,
  confirmCode,
  updatePassword,
  sendCodeAgain
};