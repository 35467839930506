<script setup lang="ts">
import {computed, ref} from "vue";

import {PasswordField, PhoneNumberField} from "@lib/components";

import invoiceApi from "../../api/invoice-api";
import {useRouter} from "vue-router";
import AuthResponse from "../../types/auth-response";
import * as Sentry from "@sentry/vue"

const router = useRouter();

const props = defineProps<{
  invoiceId: string
  errorMessage?: string
}>();

const emit = defineEmits<{
  (e: "nextClick", payload: AuthResponse): void
}>();

const loginField = ref<string>();
const passwordField = ref<string | null>(null);

const errorMessage = ref<string | undefined>();
const fieldErrors = ref<Record<string, string> | undefined>();
const parameterErrors = ref<Record<string, string> | undefined>(undefined);


const passwordError = computed(() => {
  const password = passwordField.value;

  if (!password && parameterErrors.value?.password) {
    return parameterErrors.value.password;
  }

  return undefined;
});

const phoneNumberError = computed(() => {
  const login = loginField.value;

  if (!login && parameterErrors.value?.login) {
    return parameterErrors.value.login;
  }

  return undefined;
});

async function onNext() {
  const authRequest = {
    invoiceId: props.invoiceId!,
    login: loginField.value!,
    password: passwordField.value!
  };

  const {error, result} = await invoiceApi.auth(authRequest);

  if (error) {
    console.log(error.message);
    errorMessage.value = error.message;
    fieldErrors.value = error.parameterErrors;
    return;
  }

  errorMessage.value = undefined;
  fieldErrors.value = undefined;

  Sentry.setUser({
    username: loginField.value!
  })

  emit("nextClick", result);
}

let tagArr = document.getElementsByTagName("form");
for (let i = 0; i < tagArr.length; i++) {
  tagArr[i].autocomplete = 'off';
}
</script>

<template>
  <q-form
      @submit.prevent="onNext"
      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      name="form"
  >

    <PhoneNumberField
        label="Номер кошелька"
        v-model="loginField"
        :error-message="phoneNumberError"
        id="login"
    />

    <PasswordField
        id="password"
        label="Пароль"
        v-model="passwordField"
        :error-message="passwordError"
        required
    />

    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>

    <q-btn
        type="submit"
        label="Продолжить"
        color="primary"
        class="q-ma-md"/>

    <div>
      <a href=""
         @click.prevent="router.push({name:'password-recovery', params: {invoiceId: props.invoiceId}})">
        Забыли пароль?
      </a>
    </div>

  </q-form>
</template>
