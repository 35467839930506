<script setup lang="ts">
import {computed, onMounted, ref} from "vue";

import Authenticate from "./Authenticate.vue";
import Confirm from "./Confirm.vue";
import authApi from "../../api/auth-api";
import invoiceApi from "../../api/invoice-api";
import {apiInterceptors} from "@lib/utils";
import {useQuasar} from "quasar";
import AuthResponse from "../../types/auth-response";
import {SiteNotification} from "@lib/types";
import Invoice from "../../types/invoice";

const props = defineProps<{
  invoiceId: string
}>();

const step = ref("auth");
const invoice = ref<Invoice>({
  login: "",
  amount: 0,
  comment: ""
});
const siteNotifications = ref<SiteNotification[]>([])
const errorMessage = ref<any>(null);
const commentField = ref<string | null>(null);

const $q = useQuasar();

const isMobile = computed(() => {
  const {width} = $q.screen;
  return width < 1000;
});

apiInterceptors.onUnauthorized(async () => {
  errorMessage.value = "Сессия устарела. Авторизуйтесь еще раз";
  step.value = "auth";
});

function onAuthenticated(response: AuthResponse) {
  invoice.value = response.invoice;
  siteNotifications.value = response.siteNotifications;
  step.value = "confirm";
  errorMessage.value = null;
}

async function onConfirmed() {
  step.value = "status";
  await authApi.logout();
}

onMounted(async () => {
  const {error, result} = await invoiceApi.get(props.invoiceId!);

  if (error) {
    errorMessage.value = error.message;
    return;
  }

  commentField.value = result.comment;
});
</script>

<template>
  <div class="invoice-form text-center">
    <h4 class="content-title">{{ commentField }}</h4>

    <q-stepper
        v-model="step"
        ref="stepper"
        animated
        active-color="info"
        done-color="secondary"
        :vertical="isMobile"
        flat>

      <q-step
          name="auth"
          title="Авторизация"
          icon="add_comment"
          :done="step === 'confirm' || step === 'status'"
      >
        <Authenticate
            v-if="step === 'auth'"
            :invoice-id="props.invoiceId"
            :error-message="errorMessage"
            @nextClick="onAuthenticated"
        />
      </q-step>

      <q-step
          name="confirm"
          title="Подтверждение"
          icon="assignment"
          :done="step === 'status'"
      >
        <Confirm
            v-if="step === 'confirm'"
            :invoice-id="props.invoiceId"
            :login="invoice.login"
            :sum="invoice.amount"
            :comment="invoice.comment"
            :site-notifications="siteNotifications"
            @nextClick="onConfirmed"
        />
      </q-step>

      <q-step
          name="status"
          title="Оплата"
          icon="done"
          :done="step === 'status'"
      >
        <div v-if="step==='status'">
          <div class="q-ma-sm">
            <div class="text">Ваша заявка на оплату счёта принята.</div>
            <div class="text">Спасибо!</div>
          </div>
        </div>
      </q-step>

    </q-stepper>
  </div>
</template>

<style scoped lang="scss">

.invoice-form {
  max-width: 700px;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  text-align: center;
  margin: 44px 0 20px 0;

  @media only screen and (max-width: 1000px) {
    width: 90%;
    max-width: 500px;
    margin: 30px 0 20px 0;
  }
}

.content-title {
  margin: 20px 0 0;
}

</style>