<script setup lang="ts">
import {ref, watch} from "vue";

type InputFieldType = "text" | "email" | 'number'

const props = withDefaults(defineProps<{
  id: string,
  modelValue?: string | null,
  label: string,
  type?: InputFieldType | undefined,
  errorMessage?: string,
  note?: string,
  disabled?: boolean,
  required?: boolean,
  mask?: string,
  hint?: string,
  autofocus?: boolean,
  regex?: string

}>(), {
  note: undefined,
  errorMessage: undefined,
  disabled: false,
  required: false,
})

const regex = new RegExp(props.regex ?? '');

const isRequired = [
  (value: string | undefined, rules: Record<string, unknown>): string | boolean => {

    if (!value || value.length < 0) {
      return 'Заполните поле'
    }

    // console.log(regex);
    
    if (!regex.test(value)) {
      return 'Неверный формат'
    }

    return true
  }
];


const emit = defineEmits(["update:modelValue"]);

const inputValue = ref(props.modelValue);

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue);
});

watch(() => props.modelValue, () => {
  inputValue.value = props.modelValue;
}, {
  immediate: true
});
</script>

<template>
  <div class="q-my-sm q-mr-sm">
    <q-input
        :id="props.id"
        v-model="inputValue"
        :label="props.label"
        :disable="props.disabled"
        :type="props.type"
        :error="!!props.errorMessage"
        :error-message="props.errorMessage ?? undefined"
        :autofocus="props.autofocus"
        :mask="props.mask"
        :lazy-rules="true"
        :unmasked-value="true"
        :rules="props.required ? isRequired : undefined"
        :hint="props.hint ? props.hint : undefined"
        color="secondary"
        hide-bottom-space
    >
      <template v-slot:append v-if="props.required && !props.disabled">
        <span class="requiredIcon">*</span>
      </template>

    </q-input>
    <div v-if="props.note" class="text">{{ props.note }}</div>
  </div>
</template>

