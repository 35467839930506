<script setup lang="ts">
import {computed} from "vue";
import {siteThemeHelper} from "@lib/utils";

const logoUrl = computed(() => {
  return siteThemeHelper.isDark() ? '/img/icons/w1-black.png' : '/img/icons/logo.png'
});

</script>

<template>
  <picture>
    <img :src="logoUrl" id="logo" alt="w1"/>
  </picture>

</template>
