<script setup lang="ts">
import {VueRecaptcha} from "vue-recaptcha";
import {ref} from "vue";

const props = defineProps<{
  onCaptchaVerified: (token: string) => void,
  onCaptchaExpired: () => void,
  errorMessage: string | undefined,
}>();

const reCaptcha = ref<any>(null);

function handleCaptchaVerified(reCaptchaToken: string) {

  props.onCaptchaVerified(reCaptchaToken);
}

function handleCaptchaExpired() {
  reCaptcha.value.reset();

  props.onCaptchaExpired()
}

const reCaptchaPublicKey = import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY;
</script>

<template>
  <vue-recaptcha
      ref="reCaptcha"
      @verify="handleCaptchaVerified"
      @expired="handleCaptchaExpired"
      :sitekey="reCaptchaPublicKey"
  >
  </vue-recaptcha>

  <div class="error"
       v-if="props.errorMessage"
       v-text="props.errorMessage">
  </div>
</template>