<script setup lang="ts">
import {computed, ref, watch} from "vue";
import {ReCaptcha, InputField} from "@lib/components";
import {useRouter} from "vue-router";
import passwordRecoveryApi from "../../api/password-recovery-api";
import {toasts} from "@lib/utils";

const router = useRouter();
const props = defineProps(["requestId"]);
const emits = defineEmits(["next", "requestIdNotSpecified"]);

const isBtnDisable = ref();

if (!props.requestId) {
  emits("requestIdNotSpecified");
}

const parameterErrors = ref<Record<string, string> | undefined>();

const secretCode = ref<string | null>(null);
const reCaptchaToken = ref<string | null>(null);

const secretCodeError = computed(() => {
  if (!secretCode) {
    return undefined
  }

  if (secretCode && parameterErrors.value?.secretCode) {
    return parameterErrors.value.secretCode;
  }

  return undefined;
});

watch(() => secretCode, () => {
  if (parameterErrors) {
    parameterErrors.value = undefined;
  }
})

async function confirm() {
  isBtnDisable.value = true;

  const {error} = await passwordRecoveryApi.confirmCode(props.requestId, secretCode.value!);

  isBtnDisable.value = false;

  if (error) {
    if (error.message) {
      toasts.showToast(error);
    }

    parameterErrors.value = error.parameterErrors;
    return;
  }

  parameterErrors.value = undefined;

  emits("next");
}

async function handleSendCodeAgain() {
  const {error} = await passwordRecoveryApi.sendCodeAgain(props.requestId, reCaptchaToken.value!);

  if (error) {
    if (error.message) {
      toasts.warning(error.message);
    }

    parameterErrors.value = error.parameterErrors ?? undefined
    return;
  }

  parameterErrors.value = undefined;
  toasts.success("Код отправлен");
}

function afterReCaptchaVerified(token: string) {
  reCaptchaToken.value = token;
}

function onReCaptchaExpired() {
  reCaptchaToken.value = null;
}
</script>

<template>
  <div class="registration-form">

    <h4 class="content-title">Подтверждение смены пароля</h4>

    <form @submit.prevent="confirm">
      <div class="q-ma-sm">
        <input-field
            v-model="secretCode"
            label="Код подтверждения"
            type="text"
            :error-message="secretCodeError"
            id="secretCode"
            :required="true"/>

      </div>

      <div class="flex justify-center q-my-md">
        <ReCaptcha :on-captcha-verified="afterReCaptchaVerified"
                   :on-captcha-expired="onReCaptchaExpired"
                   :error-message="parameterErrors?.reCaptchaToken">
        </ReCaptcha>
      </div>

      <a href="" @click.prevent="handleSendCodeAgain">Отправить код еще раз</a>

      <div class="q-pa-md">
        <q-btn
            label="Подтвердить"
            :disable="isBtnDisable"
            color="primary"
            size="md"
            type="submit"
        />
      </div>
    </form>
  </div>
</template>

