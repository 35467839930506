import {createRouter, createWebHistory} from "vue-router";
import PasswordRecovery from "./pages/password-recovery/PasswordRecovery.vue";
import {UserAgreement} from "@lib/pages";
import PayInvoice from "./pages/pay/PayInvoice.vue";

const routes = [
  {
    path: "/",
    component: PayInvoice,
    meta: {
      title: "W1",
      auth: true
    },
    name: "pay"
  },
  {
    path: "/pay/:invoiceId",
    props: true,
    component: PayInvoice,
    meta: {
      title: "Оплата счета",
      auth: false
    },
    name: "pay"
  },
  {
    path: "/user-agreement",
    component: UserAgreement,
    meta: {
      title: "Пользовательское соглашение",
      auth: false
    },
    name: "user-agreement"
  },
  {
    path: "/password-recovery/:invoiceId",
    component: PasswordRecovery,
    meta: {
      title: "Восстановление пароля",
      auth: false
    },
    name: "password-recovery"
  },
  {
    path: "/:path(.*)",
    redirect: () => {
      return {
        name: "pay"
      };
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
