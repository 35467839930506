<template>
  <h1 class="userAgreementTitle">Правила системы электронных денег Wallet One KZT</h1>

  <div class="cblock">
    <div class="text">
      <h1>1. Общие положения</h1>
      <p>1.1. Настоящие Правила системы электронных денег «Wallet One» (далее — Правила, Правила Системы «Wallet One») определяют единые условия и процедуры, обеспечивающие осуществление операций с
        электронными деньгами «Wallet One» и устанавливают общие требования к порядку:</p>
      <ol>
        <li>- 1.1.1. осуществления выпуска и продажи электронных денег Эмитентом;</li>
        <li>- 1.1.2. осуществления учета и погашения выпущенных Эмитентом электронных денег;</li>
        <li>- 1.1.3. взаимодействия с другими Участниками Системы электронных денег «Wallet One»;</li>
        <li>- 1.1.4. осуществления платежей и/или иных операций с использованием электронных денег и разрешению споров, возникающих при использовании электронных денег.</li>
      </ol>

      <p>
        1.2. Правила разработаны в соответствии с законодательством Республики Казахстан, в том числе Правилами выпуска, использования и погашения электронных денег, а также требований к эмитентам
        электронных денег и системам электронных денег на территории Республики Казахстан, утвержденными Постановлением Правления Национального Банка Республики Казахстан от 31 августа 2016 года № 202
        «Об утверждении Правил выпуска, использования и погашения электронных денег, а также требований к эмитентам электронных денег и системам электронных денег на территории Республики Казахстан,
        включая требования нормативно-правовых актов законодательства страны резидентства Участников системы (агенты/субагенты) в сфере приобретения/распространения электронных денег и/или
        противодействия финансированию террористической деятельности и легализации преступных доходов.
      </p>
      <p>
        1.3. Действие настоящих Правил не распространяется на отношения, связанные с осуществлением платежей и/или иных операций с использованием электронных денег в других системах электронных денег
        и
        не ограничивает Банк от участия в них.
      </p>
      <p>
        1.4. Настоящие Правила обязательны для исполнения всеми Участниками Системы электронных денег «Wallet One». Каждый из Участников гарантирует другим Участникам, что обладает необходимой
        правоспособностью (правосубъектностью), а равно всеми правами и полномочиями, необходимыми и достаточными для присоединения к настоящим Правилам и исполнения обязательств в соответствии со
        всеми
        их условиями.
      </p>
      <p>
        1.5. Заключение физическими лицами в рамках Системы электронных денег «Wallet One» договоров осуществляется в соответствии со статьей 395 Гражданского кодекса Республики Казахстан.
        При этом, Договор считается заключенным и приобретает силу с момента совершения физическим лицом действий, предусмотренных в Публичной оферте об оказании услуг безналичных платежей с
        использованием электронных денег АО «Народный Банк Казахстана» в рамках системы электронных денег «Wallet One», размещенной по адресу: <a href="https://w1.kz">www.w1.kz</a>
      </p>

      <br/>
      <h1>2. Термины и определения, используемые в настоящих Правилах</h1>
      В настоящих Правилах используются понятия, предусмотренные Законом Республики Казахстан «О платежах и платежных системах», а также следующие понятия:
      <p>
        <b>Платежный агент (Агент)</b> – юридическое лицо местной и/или международной юрисдикции, заключившее с банком-эмитентом или организацией-оператором, осуществляющее отдельные виды банковских
        операций
        или платежной организации, агентский договор по оказанию платежных услуг;
      </p>

      <p>
        <b>Платежный субагент (Субагент)</b> – юридическое лицо местной и/или международной юрисдикции или индивидуальный предприниматель, заключившие с платежным агентом субагентский договор по
        оказанию
        платежных услуг;
      </p>

      <p>
        <b>Интернет-ресурс Системы/Оператора</b> – <a href="https://w1.kz">www.w1.kz</a>;
      </p>

      <p>
        <b>Интернет-ресурс Эмитента/Банка</b> – <a href="https://halykbank.kz">halykbank.kz</a>;
      </p>

      <p>
        <b>Использование электронных денег</b> - передача электронных денег их владельцем другому участнику системы электронных денег в целях осуществления платежа по гражданско-правовым сделкам и
        (или) иных
        операций, связанных с переходом права собственности на электронные деньги;
      </p>

      <p>
        <b>Оператор системы электронных денег</b> - банк, организация, осуществляющая отдельные виды банковских операций, или платежная организация, обеспечивающие функционирование системы электронных
        денег,
        включая сбор, обработку и передачу информации, формируемой при осуществлении операций с использованием электронных денег, а также определяющие правила функционирования системы электронных
        денег
        в соответствии с договором, заключенным с эмитентом (эмитентами) электронных денег;
      </p>

      <p>
        <b>Погашение электронных денег</b> - платежная услуга, предусматривающая осуществление эмитентом электронных денег обмена выпущенных им электронных денег, предъявленных владельцем электронных
        денег
        либо подлежащих обмену без их предъявления владельцем в случаях, предусмотренных законами Республики Казахстан и/или договорными обязательствами платежных агентов/субагентов, на равную по их
        номинальной стоимости сумму денег.

        <br/><br/>
        При этом, распространение и погашение электронных денег на территории стран международной юрисдикции осуществляется в валюте, предусмотренной договорными обязательствами и/или требованиями
        законодательства страны резиденства платежных агентов/субагентов.
      </p>

      <p>
        <b>Система электронных денег «Wallet One» или Система, или Система «Wallet One» </b> - совокупность программно-технических средств, документации и организационно-технических мероприятий,
        обеспечивающих
        осуществление платежей и иных операций с использованием электронных денег путем взаимодействия оператора системы электронных денег с эмитентом электронных денег и (или) владельцами электронных
        денег;
      </p>

      <p>
        <b>Электронные деньги</b> - безусловные и безотзывные денежные обязательства эмитента электронных денег, хранящиеся в электронной форме и принимаемые в качестве средства платежа в системе
        электронных
        денег другими участниками системы;
      </p>

      <p>
        <b>Эмитент электронных денег или Эмитент</b> – АО «Народный Банк Казахстана», являющийся поставщиком платежных услуг, имеющий в соответствии с законодательством право на выпуск и погашение
        электронных
        денег;
      </p>

      <br/>
      <h1>3. Описание платежных услуг, оказываемых платежной организацией</h1>

      <p>
        3.1. Услуги по реализации (распространению и/или погашению) электронных денег – услуги по реализации электронных денег
        через платежных агентов, также непосредственно эмитентом электронных денег.
      </p>

      <p>
        3.2. Услуги по приему и обработке платежей, совершаемых с использованием электронных денег – услуги по процессингу (проведению, записи обработке и логированию) операций с электронными деньгами
        по распоряжению участников системы.
      </p>

      <br/>
      <h1>4. Порядок и сроки оказания платежных услуг клиентам платежной организации</h1>

      <p>
        4.1. Доступ к сервису Системы электронных денег «Wallet One» осуществляется одним из следующих способов:
      </p>

      <ol>
        <li>- через интернет-ресурс Системы: <a href="https://w1.kz">www.w1.kz</a>;</li>
        <li>- через специальное программное обеспечение (приложение) Оператора, созданное для мобильных устройств;</li>
        <li>- через специальное программное обеспечение (приложение) Оператора, интегрированное в Программу Системы.</li>
      </ol>

      <p>
        4.2. Операции, доступные Участникам в Системе:
      </p>
      <p>4.2.1. Участникам Системы – клиентам/физическим лицам после регистрации в Системе доступны следующие операции:</p>
      <ol>
        <li>- приобретение ЭД согласно Правилам:</li>
        <li>- безвозмездное получение ЭД от других Участников Системы – клиентов/физических лиц и осуществление операций с ЭД в Системе согласно настоящим Правилам -круглосуточно;</li>
        <li>
          - перевод ЭД на другой электронный кошелек в Системе владельцу электронных денег – клиенту/физическому лицу в порядке и на условиях, предусмотренных законодательством Республики Казахстан
          и
          настоящими Правилами;
        </li>

        <li>- предъявление находящихся в его распоряжении ЭД к погашению;</li>
        <li>- просмотр наличия, статуса, истории движения электронных денег на электронном кошельке за любой период времени;</li>
        <li>- просмотр баланса своего электронного кошелька (с актуальностью только на момент запроса);</li>
        <li>- получение справочной и иной информации касательно функционирования Системы, адресов пунктов приема и снятия платежей и/или порядка верификации электронного кошелька;</li>
        <li>- круглосуточная техническая поддержка клиентов/физических лиц и т.п.;</li>
      </ol>

      <p>- 4.2.2. Мерчантам после регистрации в Системе доступны следующие операции:</p>
      <ol>
        <li>- просмотр баланса своего электронного кошелька (с актуальностью только на момент запроса);</li>
        <li>- получение ЭД от владельца ЭД – физического лица согласно настоящим Правилам;</li>
        <li>- предъявление находящихся в его распоряжении ЭД к погашению;</li>
        <li>- получение справочной и иной информацию касательно функционирования Системы.</li>
      </ol>

      <p>- 4.2.3. Агентам/субагентам после регистрации в Системе доступны следующие операции:</p>
      <ol>
        <li>- просмотр баланса своего электронного кошелька (с актуальностью только на момент запроса);</li>
        <li>- приобретение ЭД, их распространение и предъявление к погашению в порядке и на условиях, предусмотренных настоящими Правилами и/или договорными обязательствами;</li>
        <li>- блокировка электронных кошельков пользователей/физических лиц в соответствии с требованиями настоящих Правил, внутренних правил и/или требований законодательства страны резидентства
          агента/субагента;
        </li>
        <li>- получение справочной и иной информации касательно функционирования Системы, операций и/или иных сведений в рамках договорных обязательств.</li>
      </ol>

      <p>- 4.2.4. Эмитент в Системе может осуществлять следующие операции:</p>
      <ol>
        <li>- эмиссия и реализация электронных денег в национальной валюте Республики Казахстан клиентам/физическим лицам и Агентам/субагентам - владельцам электронных кошельков, зарегистрированным в
          Системе «Wallet One»;
        </li>
        <li>- погашение электронных денег Участников Системы: физических лиц, Агентов и Мерчантов;</li>
        <li>- учет эмитированных и реализованных Эмитентом электронных денег;</li>
        <li>- обмен информацией с Оператором в соответствии с технологией работы Системы;</li>
        <li>- заключение соответствующих договоров с участниками Системы;</li>
        <li>- просмотр сведений по совершенным в Системе операциям с ЭД;</li>
        <li>- иные операции в соответствии с договором, заключенным с Оператором и в соответствии с действующим законодательством Республики Казахстан.</li>
      </ol>

      <p>
        4.3. Определенный в разделе 4.2. перечень операций для указанных Участников Системы не является исчерпывающим и может быть дополнен по мере развития Системы, в том числе, но, не ограничиваясь,
        конвертацией электронных денег Системы «Wallet One» в электронные деньги других Систем электронных денег.
      </p>

      <br/>
      <h1>5. Порядок взаимодействия с третьими лицами, обеспечивающими технологическое обеспечение платежных услуг, оказываемых платежной организацией.</h1>
      <p>5.1. Взаимодействие с третьими лицами оказывающими услуги по обеспечению платежных услуг основывается на договорных отношениях между ТОО «W1 Kazakhstan» как Оператором системы или/и АО
        «Народный Банк Казахстана» как эмитентом системы.</p>
      <p>5.2. Техническое взаимодействие с третьими лицами производится путем интеграции систем Оператора и третьих лиц на основании договора с достаточным уровнем шифрования для соблюдения
        безопасности предоставления платежных услуг.</p>
      <p>5.2. Параметры технического взаимодействия описаны в документации по техническому взаимодействию и предоставляются заинтересованным лицам по запросу.</p>

      <br/>
      <h1>6. Порядок соблюдения мер информационной безопасности</h1>
      <p>6.1. Безопасность электронных кошельков пользователей осуществляется на основании логина и пароля.</p>
      <p>6.2. Безопасность проведения платежей обеспечивается шифрованием SHA или MD5 выбираемым по предпочтению пользователя.</p>
      <p>6.3. Все соединения системы выстраиваются на основании шифрованных туннельных соединений с другими серверами.</p>

      <br/>
      <h1>7. Методы управления рисками</h1>
      <p>7.1. Методы управления рисками определены внутренним документом «Методы управления рисками применяемые в системе «Wallet One» утвержденным руководителем организации.</p>
      <p>
        7.2. Участники Системы (платежные агенты/субагенты, банк-эмитент, оператор) применяют модель управления рисками разработанную в соответствии с требованиями нормативных правовых актов страны
        резидентства сторон, регулирующих порядок работы по нештатным ситуациям и управлению рисками, при которой функции по оценке и/или управлению рисками распределены между Участниками Системы
        соответственно. Участники Системы определяют собственную структуру управления рисками присущими их виду деятельности.
      </p>

      <br/>
      <h1>8. Права и обязанности Участников</h1>
      <p>
        8.1. Участники Системы обязаны соблюдать требования, изложенные в настоящих Правилах, а также в договорах между Эмитентом (либо действующим от его имени Оператором) и Участниками Системы.
        Несоблюдение Правил и (или) договорных отношений может явиться одним из оснований для прекращения участия в Системе Участника, допустившего подобное несоблюдение.
      </p>
      <p>
        8.2. Эмитент становится участником Системы после заключения договора с Оператором. Взаимоотношения между Эмитентом и Оператором регулируются законодательством Республики Казахстан, настоящими
        Правилами Системы, а также договорами, заключенными между Эмитентом и Оператором.
      </p>

      <p>8.3. Права и обязанности Эмитента:</p>
      <p>8.3.1. Эмитент имеет следующие обязанности:</p>
      <ol>
        <li>
          - после получения денег от Участников Системы - физических лиц или Агентов выпускать электронные деньги в сумме, равной номинальной стоимости принимаемых на себя обязательств, с
          предоставлением в порядке, установленном в договорах с данными Участниками Системы, подтверждения в виде квитанции или иного документа, соответствующего требованиям законодательства
          Республики Казахстан;
        </li>
        <li>
          - при выпуске электронных денег ознакомить владельца ЭД (при их непосредственном обращении в Банк) с информацией о порядке осуществления операций с ЭД и рисках, возникающих при использовании
          ЭД, способах подачи претензий и о порядке их рассмотрения, а также видах и размерах комиссионного вознаграждения, взимаемого при осуществлении операций с использованием ЭД;
        </li>
        <li>
          - проводить претензионную работу с Участниками (при необходимости) в части, касающейся обязательств и ответственности Эмитента;
        </li>
        <li>
          - вести учет эмитированных и реализованных Эмитентом электронных денег;
        </li>
        <li>
          - обмениваться информацией с Оператором в соответствии с технологией работы Системы и Правилами Системы;
        </li>
        <li>
          - обеспечивать защиту Системы и размещенной в ней информации Участников Системы от несанкционированного доступа третьих лиц, в том числе хакерских атак, и нести ответственность перед
          Участниками Системы за нарушение данного обязательства в размере реального ущерба, причиненного Участникам Системы (за исключением случаев, если несанкционированный доступ третьих лиц
          произошел по вине самих Участников Системы), с правом предъявления в регрессном порядке требований к Оператору о возмещении убытков в соответствии с условиями заключенного с Оператором
          договора;
        </li>
        <li>
          - предоставлять сведения о владельцах и суммах принадлежащих им электронных денег, а также сведения об операциях, совершенных с использованием электронных денег, государственным органам и
          уполномоченным лицам по основаниям и в пределах, предусмотренных законодательными актами Республики Казахстан.
        </li>
      </ol>

      <p>
        8.3.2. Эмитент принимает на себя безусловное и безотзывное денежное обязательство по погашению выпущенных им ЭД в соответствии с их номинальной стоимостью в тенге. При этом погашение
        электронных
        денег, полученных Мерчантом от Участника Системы - физического лица при оплате по гражданско-правовым сделкам, осуществляется в течение трех рабочих дней со дня поступления ЭД в его пользу,
        если
        иной срок не предусмотрен договором, заключенным между Оператором и Мерчантом. В случае несанкционированного выпуска ЭД условие данного пункта не применимо.
      </p>

      <p>
        8.3.3. Эмитент несет ответственность перед владельцем ЭД в соответствии с условиями заключенных с Участниками договоров за ущерб, причиненный владельцу ЭД в случаях несанкционированного
        доступа
        к Системе, а также возникновения ошибок или сбоев в работе (функционировании) соответствующего программного обеспечения Эмитента.
      </p>

      <p>8.3.4. Эмитент имеет право:</p>
      <ol>
        <li>- передавать ЭД Агенту для распространения на условиях предварительной оплаты;</li>
        <li>- заключать договоры с Участниками в соответствии с требованиями законодательства Республики Казахстан;</li>
        <li>- взимать комиссионное вознаграждение с Участников Системы согласно тарифам Эмитента, размещенным на интернет-ресурсе Эмитента и Оператора (либо доведенным до сведения Участников Системы в
          порядке, установленном в заключенных Эмитентом с Участниками Системы договорах) за услуги, оказываемые Эмитентом Участникам Системы;
        </li>
        <li>- приостановить распространение и/или использование и/или погашение ЭД, если исполнение соответствующего денежного требования приведет к нарушению законодательства Республики Казахстан или
          требований настоящих Правил, а также в случае обнаружения несанкционированного зачисления электронных денег на электронные кошельки участников Системы;
        </li>

        <li>
          - выйти из Системы. Прекращение участия Эмитента в Системе осуществляется на основании письменного заявления Эмитента, направленного в адрес Оператора, о добровольном выходе из Системы при
          соблюдении Эмитентом следующих обязательных условий:

          <p>1. направление Оператору письменного заявления о выходе из Системы не менее чем за один месяц до предполагаемой даты выхода;</p>
          <p>
            2. погашение всех задолженностей перед Участниками Системы по операциям, осуществленным с использованием электронных денег, эмитированных Эмитентом в Системе электронных денег «Wallet
            One». В
            случае отсутствия банковских реквизитов владельцев электронных денег для осуществления Банком погашения остатков электронных денег Участникам Системы, Эмитент размещает на своем сайте
            информацию
            о прекращении деятельности в качестве Эмитента не менее, чем за 1 (один) месяц до наступления такого события, а также уведомляет всех Участников Системы, имеющих остатки ЭД на электронных
            кошельках, всеми доступными средствами (при условии указания владельцами электронных денег своих реквизитов для этих целей – номер телефона, электронный адрес, почтовый адрес и другое);
          </p>
        </li>

        <li>
          - осуществить блокирование электронного кошелька владельца ЭД в случаях:
          <p>
            1. получения уведомления от владельца ЭД, в том числе при утере, краже или несанкционированном использовании электронного кошелька;
            2. неисполнения владельцем ЭД своих обязательств, за которые в соответствии с договором, заключенным между Эмитентом и владельцем ЭД, предусмотрено блокирование электронного кошелька;
            3. совершения операций с нарушением установленных требований и условий использования электронных денег;
            4. наложения ареста на электронные деньги владельца ЭД по основаниям, предусмотренным законодательными актами Республики Казахстан;
            5. предусмотренных Законом Республики Казахстан «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма» (далее – Закон о ПОД/ФТ);
            6. по иным основаниям, предусмотренным договором между Эмитентом и владельцем ЭД. Блокирование электронного кошелька владельца ЭД не прекращает его обязательств и обязательств Эмитента,
            возникших до момента блокирования электронного кошелька.
          </p>
        </li>
      </ol>

      <p>
        8.4. Права и обязанности Оператора Системы:
      </p>

      <p>
        8.4.1. Оператор обязан:
      </p>
      <ol>
        <li>
          - обеспечить информационное и технологическое взаимодействие Участников Системы;
        </li>
        <li>
          - осуществлять обслуживание Участников Системы в соответствии с условиями настоящих Правил и заключенных с Участниками Системы договоров;
        </li>
        <li>
          - обеспечить информационное и операционное функционирование веб-сайта Системы;
        </li>
        <li>
          - в случае необходимости, совместно с Эмитентом (по согласованию), вносить изменения в настоящие Правила и соответствующие договоры, с публикацией новой редакции Правил/договора, либо текста
          изменений и дополнений к ним на интернет-ресурсе Оператора;
        </li>
        <li>
          - обеспечивать выполнение требований, установленных Законом о ПОД/ФТ, предъявляемых к субъектам финансового мониторинга;
        </li>
        <li>
          - согласовывать с Эмитентом все технологические изменения, вносимые в Систему, с последующим внесением таких изменений в настоящие Правила (в случае, если данные технологические процессы
          были регламентированы настоящими Правилами) и размещением изменений на интернет-ресурсе Системы;
        </li>
        <li>
          - осуществлять обработку операций, совершаемых в Системе;
        </li>
        <li>
          - обеспечить в Системе определение Участников Системы - физических лиц в качестве идентифицированных и/или неидентифицированных владельцев электронных денег при осуществлении ими операций с
          ЭД в Системе;
        </li>
        <li>
          - обеспечить в Системе установление ограничений в соответствии с настоящими Правилами по суммам операций идентифицированных и/или неидентифицированных владельцев электронных денег, а также
          осуществлять контроль над соблюдением в Системе данных ограничений;
        </li>
        <li>
          - на следующий банковский день с момента наступления события, уведомить Участников Системы путем размещения соответствующей информации на интернет-ресурсе Оператора о случаях введения
          Национальным Банком Республики Казахстан ограничения или запрета на осуществление Эмитентом банковских операций, отзыва Национальным Банком Республики Казахстан у Эмитента лицензии на
          осуществление банковских операций, что может привести к невозможности осуществления Эмитентом своих функций в рамках Системы;
        </li>
        <li>
          - предоставлять по запросу Эмитента, в сроки определенные Эмитентом, информацию и необходимые документы в отношении операций с ЭД, совершенных Участниками в рамках Системы;
        </li>
        <li>
          - своевременно уведомлять Участников Системы о приостановлении работы Системы в связи с плановыми техническими работами;
        </li>
        <li>
          - заключать договоры с Мерчантами о взаимодействии в Системе, размещать списки Мерчантов, с которыми заключены договоры, на интернет-ресурсе Системы;
        </li>
        <li>
          - вести претензионную работу (при необходимости) в части функционирования Системы электронных денег «Wallet One», а также осуществлять работу по разрешению диспутных (спорных) ситуаций путем
          определения отдельного закрепленного работника и телефонного номера, который должен быть размещен на интернет-ресурсе Оператора.
        </li>
      </ol>

      <p>
        8.4.2. Информация о состоянии (баланс) электронного кошелька, действиях владельца ЭД в отношении электронного кошелька, история проведения платежей, а также иная информация, имеющая значение
        для
        использования Системы, фиксируется и хранится Оператором в базе данных Системы не менее пяти лет, к которой Банк имеет доступ. Данная информация из Системы может предоставляться Банком третьим
        лицам в соответствии с требованиями действующего законодательства Республики Казахстан.
      </p>
      <p>
        8.4.3. Оператор Системы имеет право:
      </p>
      <ol>
        <li>
          - вносить изменения совместно с Эмитентом (по согласованию) в настоящие Правила Системы;
        </li>
        <li>
          - взимать комиссионное вознаграждение с Участников Системы в соответствии с условиями заключенных договоров согласно тарифам Оператора, размещенным на интернет-ресурсе Оператора за услуги,
          оказываемые Оператором Участникам Системы, в том числе, взимать вознаграждение с Участников Системы – физических лиц, являющихся Владельцами электронных денег;
        </li>
        <li>
          - приостановить и/или прекратить использование Участником ЭД как средства платежа:

          <p>1. на основании полученного от Участника уведомления;</p>
          <p>2. при нарушении Участником порядка использования ЭД.</p>
        </li>
      </ol>

      <p> 8.4.4. Оператор Системы несет ответственность за несвоевременное предоставление Эмитенту запрашиваемой информации в отношении Участников Системы - физических лиц, участвующих в
        совершении/проведении операции, влияющей или способной повлиять на надлежащее выполнение Эмитентом своих обязательств перед уполномоченными государственными органами Республики Казахстан.</p>
      <p>
        8.4.5. Оператор Системы не несет ответственность:
      </p>
      <ol>
        <li>- за содержание, достоверность и точность материалов, опубликованных на интернет-ресурсах других Участников Системы;</li>
        <li>- за нарушение другими Участниками авторских и иных прав третьих лиц.</li>
        <li>- Оператор, являясь платежной организацией вправе оказывать платежные услуги клиентам через платежного агента или платежного субагента на основании агентского договора по оказанию
          платежных услуг. Оператор при наличии регистрационного номера учетной регистрации платежной организации, присвоенного Национальным Банком, оказывает следующие виды платежных услуг:
        </li>
        <li>- услуги по приему наличных денег для осуществления платежа без открытия банковского счета отправителя денег;</li>
        <li>- услуги по реализации (распространению) электронных денег;</li>
        <li>- услуги по приему и обработке платежей, совершаемых с использованием электронных денег;</li>
        <li>- услуги по обработке платежей, инициированных клиентом в электронной форме, и передаче необходимой информации Эмитенту, для осуществления платежа и (или) перевода либо принятия денег по
          данным платежам.
        </li>
      </ol>

      <p>Оператору запрещается осуществлять иную предпринимательскую деятельность, описанную в настоящих правилах, за исключением следующих видов деятельности:</p>
      <ol>
        <li>
          - покупка, продажа, имущественный наем недвижимого имущества;
        </li>
        <li>
          - рекламные, маркетинговые, консультационные и информационные услуги;
        </li>
        <li>
          - разработка, адаптация, модификация, техническая поддержка программного обеспечения;
        </li>
        <li>
          - покупка, продажа, имущественный наем электронных терминалов, других устройств и оборудования для обработки и передачи информации;
        </li>
        <li>
          - связанной с использованием вычислительной техники и информационных технологий, в том числе информационно-технические услуги, услуги по обработке и передаче данных, создание и использование
          базы данных и информационных ресурсов;
        </li>
        <li>
          - услуги в области логистики и курьерская деятельность, включая выполнение агентской и посреднической деятельности в данных сферах;
        </li>
        <li>
          - создание и обеспечение безопасности информационных систем и сетей;
        </li>
        <li>
          - разработка и реализация средств криптографической защиты информации;
        </li>
        <li>
          - услуги по удостоверению соответствия открытого ключа электронной цифровой подписи закрытому ключу электронной цифровой подписи, а также подтверждению достоверности регистрационного
          свидетельства.
        </li>
      </ol>

      <p>
        8.4.7. Оператор представляет в Национальный Банк Республики Казахстан сведения и отчеты по формам и в порядке, определенном нормативным правовым актом Национального Банка Республики Казахстан.
      </p>

      <p>
        8.4.8. Оператор должен вести реестры своих платежных агентов и платежных субагентов по форме, согласно Приложению № 1 к настоящим Правилам.
      </p>

      <p>
        8.4.9. Оператор осуществляет контроль за соблюдением платежным агентом требований законодательства Республики Казахстан о платежах и платежных системах, а также условий оказания платежных
        услуг,
        установленных договором между платежным агентом и Оператором/Эмитентом. Настоящий контроль заключается в том, что Оператор каждые 6 (шесть) месяцев, проводит мониторинг деятельности Платежного
        агента/субагента, путем анализа текущих операций по продаже и выкупу электронных денег за отчетный период. Анализ деятельности платежного субагента производится на основании данных, полученных
        от платежного агента при проведении мониторинга его платежных субагентов. Мониторинг деятельности платежных субагентов их платежными агентами проводится каждые 6 (шесть) месяцев.
      </p>

      <p>
        8.4.10. Оператор, заключивший агентские договоры по оказанию платежных услуг, представляет в Национальный Банк Республики Казахстан сведения об этих услугах, а также о своих платежных агентах
        и
        платежных субагентах в порядке, определенном нормативным правовым актом Национального Банка Республики Казахстан.
      </p>

      <p>
        8.5. Права и обязанности Агента в Системе:
      </p>

      <p>
        8.5.1. Права и обязанности Агента определяются настоящими Правилами и договором, заключенным между Оператором и Агентом.
      </p>
      <p>
        8.5.2. При реализации ЭД Агент ознакамливает владельца ЭД с информацией о порядке осуществления операций с ЭД и рисках, возникающих при использовании ЭД, способах подачи претензий и о порядке
        их
        рассмотрения, а также видах и размерах комиссионного вознаграждения, взимаемого при осуществлении операций с использованием ЭД.
      </p>
      <p>
        8.5.3. Агент обязан не допускать нарушение ограничений, предусмотренных Правилами, при реализации Агентом ЭД идентифицированным владельцам электронных денег, и при покупке ЭД у
        идентифицированных владельцев электронных денег.
      </p>
      <p>
        8.5.4. Агент обязан предоставлять по запросу Эмитента информацию по сделкам с ЭД, совершенным Агентом с идентифицированными владельцами электронных денег – физическими лицами, в сроки,
        установленные в соответствующем запросе.
      </p>
      <p>
        8.5.5. Агент/Субагент имеет право:
      </p>

      <ol>
        <li>
          - в одностороннем порядке отказать Участнику Системы - физическому лицу в приобретении ЭД в случаях:

          <ul>
            <li>1. несоблюдения Участником Системы - физическим лицом требований настоящих Правил;</li>
            <li>2. нарушения установленных законодательством страны резидентства Агента/Субагента требований к пользованию/распоряжению электронных денег, включая правил и иных внутренних нормативных
              документов;
            </li>
            <li>3. когда сумма денег недостаточна для приобретения ЭД либо превышает сумму ЭД, доступных Участнику Системы - физическому лицу для их приобретения (согласно ограничениям, установленным
              Правилами для идентифицированных владельцев электронных денег);
            </li>
          </ul>
        </li>

        <li>- отказать в приобретении электронных денег при превышении лимитов на операции по электронному кошельку (при наличии);</li>
        <li>- осуществлять блокировку электронного(ых) кошелька(ов) согласно настоящим Правилам, требований законодательства страны резидентства и/или правилам, установленным внутренними нормативными
          документами;
        </li>
        <li>- приостанавливать/ограничивать операции в электронном кошельке в случаях наличия признаков мошенничества, незаконной деятельности и иных случаях, предусмотренных внутренними правилами;
        </li>
        <li>- требовать от клиентов/физических лиц прохождения надлежащей процедуры верификации электронного кошелька и соблюдению иных правил, установленных Агентом/Субагентом;</li>
        <li>- принимать меры по надлежащей проверке клиента/физического лица, установленные законодательством страны резидентства Агента/Субагента и правилами внутренних нормативных документов;</li>
        <li>- взимать комиссионное вознаграждение с физических лиц - Участников Системы согласно тарифам Агента/Субагента, размещенным на сайте Оператора и/или иным способом за услуги, оказываемые
          Агентом/Субагентом (продажа ЭД, вывод ЭД);
        </li>
        <li>- осуществлять реализацию ЭД через электронные терминалы, позволяющие совершать операции по приему и/или выдаче наличных денег, пункты приема наличных денег и иные способы, не
          противоречащие законодательству Республики Казахстан и/или законодательству страны резидентства Агента.
        </li>
      </ol>

      <p>
        8.5.6. Наличные деньги, принятые Агентом/Субагентом, не являющимися банками второго уровня Республики Казахстан, при реализации ими ЭД Участникам Системы - физическим лицам, подлежат
        зачислению
        на банковские счета Агентов в порядке и сроки, предусмотренные договором между Оператором и Агентом, если иное не предусмотрено договором.
      </p>

      <p>
        8.5.7. Агент/Субагент несет ответственность за несвоевременное предоставление Эмитенту запрашиваемой информации в отношении Участников Системы - физических лиц, участвующих в
        совершении/проведении операции, влияющей или способной повлиять на надлежащее выполнение Эмитентом своих обязательств перед уполномоченными государственными органами.
      </p>

      <p>
        8.5.8. Платежный агент обеспечивает предоставление Оператору информации о привлеченных платежных субагентах в целях включения их в указанный реестр и подключения к Системе в порядке,
        определенном договором между ними и/или иной договорённости сторон.
      </p>

      <p>
        8.5.9. Платежный агент осуществляет контроль за соблюдением платежным субагентом требований настоящих Правил, договорных обязательств и условий оказания платежных услуг, установленных
        договором
        между платежным агентом и Оператором, в порядке, определенном договором между платежным агентом и (или) платежным субагентом, а также между платежным агентом и Оператором.
      </p>

      <p>
        8.5.10. Проводить и применять меры надлежащей проверки клиента/физического лица в соответствии с требованиями законодательства по противодействию финансирования террористической деятельности и
        отмыванием преступных доходов, включая иные требования законодательства страны резидентсства, регулирующие деятельность Агента;
      </p>

      <p>
        8.5.11. Запрашивать необходимую информацию/документы у Эмитента/Оператора в рамках договорных обязательств;
      </p>

      <p>8.5.12. Порядок взаимодействия между Участниками Системы, права и обязанности, лимиты, тарифы и иные условия работы Агента/Субагента с Системой регулируются условиями соответствующих
        договоров.</p>

      <p>8.6. Права и обязанности Мерчанта:</p>
      <p>
        8.6.1. Мерчант обязан:
      </p>
      <ol>
        <li>
          - принимать ЭД от Участников Системы - физических лиц в качестве платежей за реализованные товары или услуги, а также по иным гражданско-правовым сделкам;
        </li>
        <li>
          - в случае приостановления или прекращения реализации Мерчантом товаров/услуг/работ незамедлительно, но не позднее дня приостановления или прекращения реализации Мерчантом
          товаров/работ/услуг уведомить об этом Оператора Системы и Эмитента;
        </li>
        <li>
          - оплачивать комиссию Эмитента/Оператора, за обработку Заявления на погашение денег в безналичном порядке с перечислением на банковский счет;
        </li>
        <li>
          - размещать и поддерживать достоверную информацию о Системе электронных денег «Wallet One» в собственных рекламно-информационных материалах в части, касающейся способа оплаты
          товаров/работ/услуг через Систему;
        </li>
        <li>
          - неукоснительно предоставлять Оператору пакет документов, указанный в пункте 8.8 настоящих Правил, необходимый для регистрации/открытия электронного кошелька и совершения операций;
        </li>
        <li>
          - выполнять иные обязательства, возложенные на Мерчанта согласно настоящим Правилам и условиям договора, подписанного между Мерчантом и Оператором.
        </li>
      </ol>

      <p>
        8.6.2. Мерчант вправе:
      </p>

      <ol>
        <li>
          - стать участником Системы электронных денег «Wallet One» согласно настоящим Правилам и условиям договора заключенного между Мерчантом и Оператором;
        </li>
        <li>
          - предъявить находящиеся в его распоряжении ЭД к погашению Эмитентом в соответствии с условиями, предусмотренными договором, заключенным между Мерчантом и Оператором.
        </li>
      </ol>

      <p>
        8.7. Права и обязанности Участника Системы - физического лица в Системе:
      </p>
      <p>
        8.7.1. Физическое лицо - Участник Системы обязано:
      </p>
      <ol>
        <li>
          - выполнять требования Правил Системы, Эмитента и Оператора, Агента/субагента предъявляемые в связи с предоставлением доступа к совершению операций с ЭД в Системе;
        </li>
        <li>
          - производить оплату комиссионного вознаграждения (при наличии) за совершение операций с ЭД в Системе согласно действующим тарифам Оператора, Эмитента, Агента/субагента, размещенным на
          интернет-ресурсе Системы Оператора и Эмитента (в отношении тарифов, устанавливаемых Эмитентом);
        </li>
        <li>
          - не разглашать/передавать другим лицам информацию о собственных параметрах авторизации (имя пользователя, пароль). При этом все расходы и убытки, которые могут возникнуть в связи с этим,
          Участник Системы - физическое лицо несёт самостоятельно и не вправе требовать от Оператора/Эмитента, Агента/Субагента их возмещения;
        </li>
        <li>
          - самостоятельно обеспечивать собственное подключение к Системе «Wallet One» через интерент-соединение;
        </li>
        <li>
          - немедленно информировать Оператора/Эмитента и/или Агента/Субагента посредством Системы при обнаружении несанкционированного доступа, либо о подозрениях на несанкционированный доступ к
          электронным кошелькам Участника Системы - физического лица с целью блокирования доступа к совершению операций с электронными деньгами через Систему;
        </li>
        <li>
          - контролировать состояние своих электронных кошельков для выявления случаев несанкционированного доступа;
        </li>
        <li>
          - осуществлять использование Системы электронных денег «Wallet One» в полном соответствии с Правилами Системы и Оферты;
        </li>
        <li>
          - соблюдать правила безопасности и следовать правилам пользования Системой, после каждого сеанса работы обеспечивать закрытие всех сессий соединения с сетью Интернет, открытых для работы с
          Системой;
        </li>
        <li>
          - при необходимости проведения платежа с использованием электронных денег обеспечить достаточную сумму электронных денег для проведения оплаты и комиссионного вознаграждения согласно
          действующим тарифам Эмитента/Оператора и/или Агента/Субагента;
        </li>
        <li>
          - не использовать электронные деньги в качестве платежей и/или переводов, связанных с предпринимательской деятельностью, незаконной (мошеннической) деятельностью и/или деятельностью,
          связанной с финансированием террористической деятельности/отмыванием преступных доходов;
        </li>
        <li>
          - при прохождении идентификации в Системе неукоснительно предоставить и заполнить документы в соответствии с Правилами;
        </li>
        <li>
          - проходить в надлежащем порядке верификацию согласно настоящим Правилам и/или требованиям Агента/Субагента;
        </li>
        <li>
          - соблюдать лимиты, установленные настоящими Правилами (при наличии) и/или требованиями Агента/Субагента;
        </li>
        <li>
          - исполнять иные обязанности согласно настоящим Правилам и условиям Оферты, включая правила Агентов/Субагентов.
        </li>
      </ol>

      <p>
        8.7.2. Физическое лицо - Участник Системы имеет право:
      </p>
      <ol>
        <li>
          - совершать операции с ЭД посредством Системы электронных денег «Wallet One» на условиях, предусмотренных настоящими Правилами Системы, правилами Агента/Субагента и условиями Оферты;
        </li>
        <li>
          - требовать от Оператора/Эмитента и Агента/Субагента надлежащего исполнения своих обязательств согласно Правилам Системы и условиям Оферты;
        </li>
        <li>
          - отказаться от услуг Системы электронных денег «Wallet One», закрыв электронные кошельки (блокировка) в Системе согласно Правилам Системы электронных денег «Wallet One» и условиям Оферты.
        </li>
        <li>
          - запрашивать информацию о порядке пользования, верификации, тарифах и иных сведений по электронному кошельку;
        </li>
        <li>
          - обращаться в круглосуточную службу технической поддержки на официальном сайте Оператора по вопросам пользования электронного кошелька.
        </li>
      </ol>

      <p>
        8.7.3. Физическое лицо - Участник Системы оплачивает оказанные Оператором услуги в рамках Системы в порядке, предусмотренном Офертой, либо в порядке, размещенным Оператором на интернет-ресурсе
        Системы, а также в пунктах приема снятия платежей/терминалах Агента/Субагента. Оператор вправе взимать вознаграждение с Участника Системы - физического лица за оказание услуг в Системе
        электронными деньгами.
      </p>

      <p>
        8.7.4. По всем возникающим вопросам Участники Системы могут обратиться в службу поддержки по контактным телефонам и электронным адресам, размещенным на интернет – ресурсе Оператора и по
        телефону: +7 (777) 622-32-32, включая круглосуточную техническую поддержку на сайте Оператора.
      </p>

      <br/>
      <h1>9. Порядок присоединения к Системе электронных денег «Wallet One»</h1>
      <p>9.1. Присоединение Участников к Системе и использование ими ЭД осуществляется в соответствии с настоящими Правилами Системы и условиями заключенных договоров, в том числе условиями
        Оферты.</p>
      <p>9.2. Присоединение Участников к Системе производится на бесплатной основе.</p>
      <p>9.3. Порядок присоединения клиентов/физических лиц к Системе:</p>
      <p>
        9.3.1. Участником Системы «Wallet One» может стать любое дееспособное физическое лицо, достигшее возрастного ценза, установленного Участниками Системы и прошедшее процедуру регистрации в
        Системе
        согласно настоящим Правилам, с дальнейшим прохождением верификации согласно правилам Участников Системы.
      </p>
      <p>
        9.3.2. Для присоединения к Системе и осуществления операций с ЭД необходимо ознакомление и согласие физического лица с настоящими Правилами и условиями Оферты, размещенными на
        интернет-ресурсах
        Системы Оператора и/или Эмитента.
      </p>
      <p>
        9.3.3. Акцепт Оферты со стороны клиента/физического лица с Эмитентом и Оператором, его присоединение к Системе и признание им условий настоящих Правил, осуществляются автоматически при
        прохождении физическим лицом регистрации электронного кошелька в Системе путем проставления отметки о согласии с Правилами и Офертой при заполнении регистрационных форм на интернет-ресурсе
        Оператора в соответствующем поле регистрационной формы. Согласие с условиями Оферты обозначает полное и безоговорочное принятие клиентом/физическим лицом условий данного договора без
        ограничений
        и равносилен заключению письменного договора.
      </p>
      <p>
        9.4. Порядок присоединения Агентов/Субагентов к Системе:
      </p>
      <p>
        9.4.1. Присоединение Агента к Системе осуществляется путем подписания договора с Оператором в соответствии с требованиями законодательства Республики Казахстан и настоящих Правил. Также Агент
        обязуется уведомить Оператора о привлечении Субагента в целях включения его Оператором в реестр и подключения к Системе, с надлежащими правами и обязанностями, предусмотренными договором между
        оператором и Агентом и/или иной их договорённостью.
      </p>

      <p>
        9.5. Порядок присоединения Мерчантов к Системе:
      </p>
      <p>
        9.5.1. Присоединение Мерчанта к Системе осуществляется путем письменного заключения договора между Мерчантом и Оператором в соответствии с требованиями законодательства Республики Казахстан и
        настоящих Правил.
      </p>

      <br/>
      <h1>10. Регистрация и открытие электронных кошельков клиентов/физических лиц в Системе. Ограничения на проведение операций физическими лицами в Системе</h1>
      <p>
        10.1. Регистрация физического лица в Системе осуществляется непосредственно на интернет-ресурсе Оператора или через клиентские приложения Оператора (при наличии), следуя инструкциям по
        регистрации электронного кошелька. Физическое лицо, прошедшее регистрацию по данной процедуре, получает в Системе статус неидентифицированного владельца электронных денег. Для получения
        статуса
        идентифицированного владельца электронных денег, физическое лицо должно пройти процедуру верификации в соответствии с требованиями действующего законодательства Республики Казахстан, настоящих
        Правил и/или правил Агентов/Субагентов;
      </p>

      <p>
        10.2. Завершение регистрации физического лица подтверждается получением соответствующего сообщения на адрес электронной почты (e-mail)/мобильный телефон физического лица, указанный им в
        заявлении на регистрацию электронного кошелька.
      </p>

      <p>
        10.3. После выполнения физическим лицом процедур по регистрации, Система открывает Участнику Системы - физическому лицу электронный кошелек и присваивает электронному кошельку уникальный
        идентификационный номер в Системе. Управление Участником Системы-физическим лицом своим электронным кошельком производится через веб-сайт Системы или клиентские приложения, распространяемые
        Оператором.
      </p>

      <p>
        10.4. После регистрации в Системе физическое лицо - Участник Системы получает доступ через интернет-ресурс Оператора к своему электронному кошельку. Для снятия ограничений на услуги Оператора,
        владельцу не идентифицированного электронного кошелька необходимо пройти процедуру верификации, согласно настоящих Правил и/или правил, установленных Агентом/Субагентом.
      </p>

      <p>
        10.5. Идентификация физического лица - Участника Системы проводится Оператором согласно разделу 10 настоящих Правил и/или его платежными агентами/субагентами в соответствии с настоящими
        Правилами Системы и условиями заключенных договоров, их внутренних правил, установленных нормативными документами, в том числе условиями Оферты.
      </p>

      <p>
        10.7. В Системе действуют следующие ограничения для Участников Системы - физических лиц по суммам операций с электронными деньгами (в том числе на приобретение электронных денег у Эмитента и
        Агента/Субагента), согласно которым сумма единовременной (одной) операции с электронными деньгами не должна превышать:
      </p>
      <ol>
        <li>
          - для неидентифицированного владельца электронных денег – сумму, равную стократному размеру месячного расчетного показателя, установленного на соответствующий финансовый год законом
          Казахстана о республиканском бюджете;
        </li>
        <li>
          - для идентифицированного владельца электронных денег – сумму равную пятисоткратному размеру месячного расчетного показателя, установленного на соответствующий финансовый год законом
          Казахстана о республиканском бюджете;
        </li>
      </ol>

      <p>
        10.7.1. Ограничения по сумме единовременной (одной) или нескольких операций на снятие и/или пополнение электронного кошелька для клиентов/плательщиков Агентов/Субагентов устанавливаются в
        соответствии с требованиями законодательства страны резидентства Агента/Субагента, внутренних правил и/или условий договорных обязательств.
      </p>
      <p>
        10.8. Максимальная сумма электронных денег, хранимых на одном электронном устройстве владельца электронных денег - физического лица согласно настоящим Правилам Оператора (за исключением
        Агента/Субагента), не превышает сумму:
      </p>
      <ol>
        <li>
          - для неидентифицированного владельца электронных денег – сумму, равную двухсоткратному размеру месячного расчетного показателя, установленного на соответствующий финансовый год законом
          Казахстана о республиканском бюджете;

          <br/>
          Условия настоящего пункта не распространяются на операции по погашению электронных денег. Для возможности погашения электронных денег (вне зависимости от суммы) Участник Системы - физическое
          лицо должно пройти процедуру верификации. Верификация осуществляется в порядке, установленном настоящими Правилами Оператора и/или внутренними правилами Агента/Субагента.
        </li>
      </ol>

      <p>
        10.9. После регистрации физического лица в Системе история всех его платежей и иных операций с ЭД будет храниться в Системе и будет доступна Участнику Системы - физическому лицу круглосуточно
        после входа в Систему.
      </p>
      <p>
        10.10. В Системе могут действовать ограничения/лимиты для Участников Системы - физических лиц по суммам операций с электронными деньгами (в том числе на приобретение электронных денег у
        Эмитента
        и Агента/Субагента), которые Участники Системы (Эмитент/оператор и/или Агент/Субагент) устанавливают самостоятельно в соответствии с требованиями законодательства страны резидентства
        Участников
        Системы и/или договорными обязательствами между ними.
      </p>
      <p>
        10.11. Предоставленный Участником Системы - физическим лицом Оператору и/или Агенту/Субагенту оригинал документа, удостоверяющего личность, после обозрения и снятия с него копии или скана
        возвращается Участнику Системы - физическому лицу.
      </p>
      <p>
        10.12. Изменение статуса пользователя в Системе с неидентифицированного на идентифицированного владельца электронных денег возможно после осуществления всех необходимых мероприятий,
        предусмотренных действующим законодательством страны резидентства Оператора и/или Агента/Субагента.
      </p>

      <br/>
      <h1>11. Регистрация и открытие электронных кошельков Агентов/Субагентов и Мерчантов</h1>
      <p>
        11.1. Регистрация Агентов/Субагентов и открытие им электронных кошельков в Системе производится Оператором при наличии договора Агента с Оператором и при предоставлении Оператору всех
        необходимых документов согласно настоящим Правилам и внутренним процедурам Эмитента/Оператора, включая сведения о привлекаемых Субагентах, в целях включения их в реестр и подключения к
        Системе.
      </p>
      <p>
        11.2. За открытие электронного кошелька в Системе с Агента/Субагента может взиматься комиссия в размере согласно тарифам Оператора. Порядок оплаты комиссии, взимаемой Оператором с
        Агента/Субагента устанавливается в соответствующем договоре, заключаемом между Агентом и Оператором, либо в порядке, размещенном на интернет-ресурсе Системы.
      </p>
      <p>
        11.3. Регистрация Мерчантов и открытие им электронных кошельков в Системе производится Оператором при наличии Договора, заключенного между Мерчантом и Оператором, через интернет-ресурс
        Оператора.
      </p>
      <p>
        11.4. В случае регистрации Мерчанта через интернет-ресурс Оператора Мерчант следует инструкциям по регистрации.
      </p>
      <p>11.5. Регистрация Мерчанта через интернет-ресурс производится путем присвоения Системой Мерчанту индивидуального кода Участника, позволяющего однозначно его идентифицировать.</p>
      <p>
        11.6. При осуществлении регистрации Мерчанта в отделении у Оператора согласно заявлению Мерчанта об открытии ему электронного кошелька, работник Оператора производит ввод данных,
        предусмотренных
        Системой.
      </p>
      <p>
        11.7. Завершение регистрации Мерчанта подтверждается получением соответствующего сообщения на адрес электронной почты (e-mail)/мобильный телефон Мерчанта, указанный им в заявлении на
        регистрацию, и открытием ему в Системе электронного кошелька. Управление электронным кошельком Мерчантом производится посредством удаленного доступа.
      </p>
      <p>
        11.8. Мерчант для регистрации в Системе (подтверждения регистрации в Системе) предоставляет Оператору следующие документы:
      </p>
      <p>
        11.8.1. для юридического лица – резидента Республики Казахстан:
      </p>
      <ol>
        <li>- Справка о государственной регистрации (перерегистрации) юридического лица с электронного портала egov.kz, содержащая сведения о Мерчанте, его руководителях и учредителях;</li>
        <li>- копия устава юридического лица либо документ, подтверждающий факт осуществления деятельности на основании типового устава;</li>
        <li>- копия свидетельства о постановке на регистрационный учет по налогу на добавленную стоимость, при наличии;</li>
        <li>- копия документа, удостоверяющего личность первого руководителя и/или иного физического лица, действующего от имени юридического лица;</li>
        <li>- оригинал либо нотариально заверенная копия доверенности от юридического лица на физическое лицо, действующее от имени юридического лица (за исключением первого руководителя);</li>
        <li>- для первого руководителя – копии документов, заверенные печатью юридического лица, подтверждающие полномочия первого руководителя (приказ, решение уполномоченного органа юридического
          лица о назначении первого руководителя);
        </li>
        <li>- Статистическая карточка (если имеется);</li>
      </ol>

      <p>
        11.8.2. для юридического лица/ филиала/представительства – нерезидента Республики Казахстан, легализованные либо апостилированные в соответствии с законодательством Республики Казахстан или
        международным договором, одним из участников которого является Республика Казахстан:
      </p>
      <ol>
        <li>- нотариально удостоверенная копия выписки из торгового реестра либо другой документ аналогичного характера, содержащий информацию об органе, зарегистрировавшем юридическое
          лицо-нерезидента, регистрационном номере, дате и месте регистрации, в установленном порядке заверенные переводом на государственный или русский язык;
        </li>
        <li>- для филиалов и представительств юридических лиц-нерезидентов нотариально заверенные копии документа установленной формы, выданного уполномоченным органом, подтверждающего факт
          прохождения государственной регистрации (перерегистрации);
        </li>
        <li>- копия свидетельства о постановке на регистрационный учет по налогу на добавленную стоимость, при наличии;</li>
        <li>- копия документа, удостоверяющего личность первого руководителя и/или иного физического лица, действующего от имени юридического лица;</li>
        <li>- оригинал либо нотариально удостоверенная копия доверенности от юридического лица на физическое лицо, действующее от имени юридического лица/филиала/представительства;</li>
        <li>- для индивидуального предпринимателя, частного нотариуса, адвоката, частного судебного исполнителя:</li>
        <li>- копия документа, подтверждающего регистрационный учет в качестве индивидуального предпринимателя, частного нотариуса, адвоката, частного судебного исполнителя, либо распечатка или
          отображение содержания электронного документа на бумажном носителе, если указанный документ был выдан в форме электронного документа;
        </li>
        <li>- копия документа, удостоверяющего личность индивидуального предпринимателя, частного нотариуса, адвоката, частного судебного исполнителя;</li>
        <li>- нотариально заверенная доверенность на физическое лицо, действующее от имени индивидуального предпринимателя, частного нотариуса, адвоката, частного судебного исполнителя (в случае
          обращения к Оператору представителя индивидуального предпринимателя, частного нотариуса, адвоката, частного судебного исполнителя);
        </li>
        <li>- копия документа, удостоверяющего личность физического лица, действующего от имени индивидуального предпринимателя, частного нотариуса, адвоката, частного судебного исполнителя.</li>
      </ol>

      <br/>
      <h1>12. Выпуск электронных денег Участникам Системы - физическим лицам и Агентам</h1>
      <p>
        12.1. Выпуск электронных денег (эмиссия) осуществляется Эмитентом после получения денег от Участников Системы - физических лиц, либо от Агентов, при условии регистрации физического лица или
        Агента в Системе согласно Правилам и при наличии у них открытых электронных кошельков в Системе. Эмитент осуществляет выпуск ЭД Агентам, заключившим с Оператором договор на оказание агентских
        услуг в Системе.
        <br/>
        Выпуск ЭД Субагенту, порядок проведения платежей между Субагентом и Участниками Системы (владелец электронного кошелька, Агент) осуществляется в соответствии с договорными обязательствами
        между
        Агентом и Субагентом.
      </p>

      <p>12.2. Выпуск ЭД Участнику Системы - физическому лицу осуществляется Эмитентом после внесения Участником Системы - физическим лицом платежа следующими способами:</p>
      <p>
        12.2.1. безналичный перевод денег на банковский счет Эмитента;
      </p>
      <p>
        12.2.2. взнос наличных денег в кассу Эмитента.
      </p>
      <p>
        При внесении либо перечислении денег Эмитенту Участник Системы - физическое лицо указывает идентификационный код электронного кошелька, открытый им в Системе, на который зачисляются
        приобретаемые им ЭД.
      </p>
      <p>
        12.3. Приобретение Агентом у Эмитента электронных денег осуществляется путем безналичного перевода денег на банковский счет Эмитента с указанием номера пополняемого электронного кошелька и
        указанием цели перевода «Приобретение электронных денег Wallet One».
      </p>
      <p>
        12.4. При выпуске ЭД ответственный работник Банка-Эмитента обязан ознакомить Участника Системы - физическое лицо (при обращении физического лица в Банк-Эмитент) с информацией о порядке
        осуществления операций с электронными деньгами и рисках, возникающих при использовании ЭД, способах подачи претензий и о порядке их рассмотрения, а также видах и размерах комиссионного
        вознаграждения, взимаемого при осуществлении операций с использованием ЭД.
      </p>
      <p>
        12.5. В случае внесения денег третьими лицами для зачисления электронных денег на электронный кошелек Участника Системы - физического лица, права и обязанности в отношении
        приобретенных/безвозмездно полученных ЭД возникают у владельца электронного кошелька. Данные действия третьих лиц оцениваются Эмитентом, как совершенные третьим лицом в интересах владельца
        электронного кошелька и не порождают прав и обязанностей между третьими лицами и Эмитентом.
      </p>
      <p>
        12.6. Эмитент обеспечивает соответствие общей суммы денег, принятых от владельцев ЭД, отраженной на его балансовом счете, сумме ЭД, находящейся на позиции Эмитента в Системе.
      </p>
      <p>
        12.7. Электронные деньги Участника Системы - физического лица и Агента считаются выпущенными Эмитентом в обращение с момента отражения информации о доступной сумме электронных денег в
        электронном кошельке Участника Системы - физического лица/Агента.
      </p>
      <p>
        12.8. Оператор осуществляет проверку полученных от Эмитента данных и отражает указанную в электронном сообщении Эмитента сумму выпуска ЭД на позиции Эмитента в Системе и на электронном
        кошельке
        владельца электронных денег.
      </p>
      <p>
        12.9. Общая сумма выпущенных Эмитентом ЭД (эмиссии), находящихся на электронных кошельках всех Участников Системы (Агентов, физических лиц и Мерчантов, не успевших погасить свои ЭД, полученные
        ими за проданные товары/работы/услуги), должна быть равна сумме ЭД, отраженной на позиции Эмитента в Системе. При обнаружении Эмитентом, либо Оператором расхождения – Оператор и Эмитент имеют
        право осуществить блокирование Системы до выяснения причин такого расхождения.
      </p>
      <p>
        12.10. При выпуске электронных денег Участникам Системы - физическим лицам/Агентам, Эмитент имеет право взимать комиссионное вознаграждение, а также плату за дополнительные услуги согласно
        заключенным договорам и в соответствии с тарифами Эмитента.
      </p>

      <br/>
      <h1>13. Реализация электронных денег Участникам Системы - физическим лицам через Агентов/Субагентов</h1>
      <p>13.1. Приобретение ЭД Участниками Системы - физическими лицами для их зачисления на открытый электронный кошелек Участника Системы - физического лица может осуществляться либо через Эмитент
        согласно разделу 8 Правил, либо через Агентов/Субагентов.</p>
      <p>
        13.2. При внесении либо перечислении денег Агенту/Субагенту Участник Системы - физическое лицо указывает идентификационный код электронного кошелька, открытый в Системе, на который зачисляются
        приобретаемые им ЭД.
      </p>
      <p>
        13.3. В случае внесения денег третьими лицами для зачисления ЭД на электронный кошелек Участника Системы - физического лица, права и обязанности в отношении приобретенных/безвозмездно
        полученных
        ЭД возникают у владельца электронного кошелька. Данные действия третьих лиц оцениваются Агентом/Субагентом, как совершенные третьим лицом в интересах владельца электронного кошелька и не
        порождают прав и обязанностей между третьими лицами и Агентом/Субагентом.
      </p>
      <p>
        13.4. Агент/Субагент обязан обеспечить контроль ограничений сумм операций с ЭД, установленных настоящими Правилами и/или внутренними правилами Агентов/Субагентов для идентифицированных и/или
        неидентифицированных владельцев электронных денег. Превышение лимита не допускается.
      </p>
      <p>
        13.5. При реализации ЭД Участникам Системы - физическим лицам, Агент/Субагент имеет право взимать комиссионное вознаграждение в соответствии с условиями настоящих Правил.
      </p>

      <br/>
      <h1>14. Идентификация Участника Системы – физического лица</h1>
      <p>
        14.1. Физическое лицо - Участник Системы может по собственному желанию в любой момент пройти процедуру идентификации, обратившись к Оператору и/или Агенту/Субагенту. Для возможности покупки у
        Эмитента и (или) Агента/Субагента электронных денег и совершения Участником Системы - физическим лицом платежей и переводов с ЭД, превышающих ограничения (при наличии), установленные Правилами
        для идентифицированного и/или неидентифицированного владельца электронных денег, а также в случае погашения или вывода электронных денег, Участник Системы - физическое лицо должен в
        обязательном
        порядке пройти процедуру верификации.
      </p>
      <p>
        14.2.Если идентификацию/верификацию проходит несовершеннолетний либо лицо ограниченное в дееспособности, то Эмитенту и/или Агенту/Субагенту должно быть предоставлено согласие законного
        представителя Участника Системы – физического лица на совершение сделок в рамках Системы, оформленное в соответствии с требованиями законодательства страны резидентства Участников Системы.
      </p>

      <br/>
      <h1>15. Осуществление платежей и иных операций с использованием ЭД</h1>
      <p>
        15.1. Платежи с использованием ЭД осуществляются с соблюдением требований действующего законодательства Республики Казахстан и/или страны резиденства Агента/Субагента. Расчеты электронными
        деньгами представляют собой вид безналичных расчетов, осуществляемых в форме перевода электронных денег без открытия банковского счета.
      </p>
      <p>
        15.2. Перевод ЭД осуществляется в Системе на основании распоряжений (указаний) Участников Системы - физических лиц/Агентов в пользу других Участников Системы – получателей ЭД.
        Операции по переводу ЭД с одного электронного кошелька на другой осуществляются в Системе незамедлительно, за исключением случаев приостановления операций либо невозможности их проведения по
        основаниям, предусмотренным законодательством Республики Казахстан и настоящими Правилами, требованиями законодательства страны резидентства Агентов/Субагентов.
      </p>
      <p>
        15.3. Владелец ЭД – физическое лицо осуществляет платежи и иные операции с использованием ЭД в пределах остатка ЭД на своем электронном кошельке и с учетом ограничений для идентифицированных
        и/или неидентифицированных владельцев электронных денег, установленных настоящими Правилами и/или правилами Агентов/Субагентов, в том числе следующие операции:
      </p>
      <p>
        15.3.1. платежи в пользу Мерчантов - оплата товаров, работ и услуг;
      </p>
      <p>
        15.3.2. перевод ЭД в пользу иных Участников Системы - физических лиц и Агентов/Субагентов.
      </p>
      <p>
        15.4. Платеж с использованием ЭД осуществляется на основании соответствующего указания владельца ЭД, сформированного и переданного через Систему, в соответствии с условиями осуществления
        платежа посредством личного кабинета владельца ЭД на интернет-ресурсе Оператора.
      </p>
      <p>
        15.5. Иные ограничения по видам и суммам операций с ЭД и условия их применения с учетом требований действующего законодательства Республики Казахстан и в зависимости от статуса владельца ЭД
        могут устанавливаться Эмитентом по согласованию с Оператором и размещаться на интернет-ресурсе Оператора и Эмитента.
      </p>
      <p>
        15.6. За проведение операций с электронными деньгами Оператором/Эмитентом может взиматься комиссионное вознаграждение согласно договорам с Участниками Системы и тарифам Эмитента/Оператора.
      </p>
      <p>
        15.7. Покупка товара (работы, услуги) в кредит в рамках Системы электронных денег «Wallet One» не допускается.
      </p>
      <p>
        15.8. Уменьшение остатка ЭД в бесспорном безакцептном порядке (без распоряжения/указания Участника) осуществляется в случаях и на условиях, предусмотренных настоящими Правилами и заключенными
        в рамках Системы договорами.
      </p>
      <p>
        15.9. Завершение перевода ЭД наступает в момент одновременного уменьшения в Системе остатка ЭД Участника (отправителя ЭД) и увеличения остатка ЭД другого Участника Системы (получателя ЭД) на
        сумму перевода ЭД. Исключением является случай, когда одновременное увеличение остатка ЭД невозможно в силу превышения при переводе установленных настоящими Правилами ограничений по сумме
        перевода.
      </p>
      <p>
        15.10. Возврат электронных денег в Системе с электронного кошелька получателя на электронный кошелек отправителя возможен в следующих случаях:
      </p>
      <p>
        15.10.1. по основаниям, предусмотренным законодательством Республики Казахстан и/или страны резидентсва Агента/Субагента, регламентирующим возврат денег по платежам и переводам, в частности:
      </p>
      <ul>
        <li>
          1) при установлении факта несанкционированности платежа, осуществляемого путем перевода электронных денег;
        </li>
        <li>
          2) при допущении в Системе ошибочного указания реквизитов получателя перевода (при условии, что отправитель правильно указал реквизиты получателя);
        </li>
        <li>
          3) если указание на перевод электронных денег передано повторно.
        </li>
      </ul>

      <p>
        Возврат электронных денег на электронный кошелек отправителя осуществляется Эмитентом (с обеспечением такой операции в Системе Оператором) в соответствии с требованиями законодательства
        Республики Казахстан либо Агентом/Субагентом в срок не позднее 3 (трех) рабочих дней с даты обнаружения основания для возврата электронных денег, если иной срок не установлен законодательством
        Республики Казахстан или страны резидентства Агента/Субагента, путем безакцептного изъятия Эмитентом электронных денег с электронного кошелька получателя (без получения указания на перевод
        либо
        дополнительного согласия получателя);
      </p>

      <p>
        15.10.2. в случае допущения отправителем электронных денег ошибки в своем указании на перевод электронных денег (в частности, указания неверного номера электронного кошелька).
        Для возможности возврата электронных денег отправитель ЭД должен обратиться к Оператору и/или Агенту/Субагенту в письменной форме либо путем направления электронного сообщения с
        предоставлением
        документов, идентифицирующих отправителя перевода и подтверждающих проведение операции (чека/квитанции или выписки). После чего Оператор и/или Агент/Субагент оповещает получателя перевода о
        допущенной отправителем ошибке и о необходимости возврата электронных денег данному лицу любым доступным способом связи, если иное не предусмотрено внутренними правилами. Возврат электронных
        денег отправителю осуществляется в Системе только на основании указания получателя такого ошибочного перевода о возврате электронных денег отправителю (а в случае, если получателем окажется
        Мерчант – только на основании его согласия на безакцептное изъятие денег с его электронного кошелька), при этом расходы по уплате комиссий (при их наличии) при переводе возлагаются на
        отправителя, допустившего ошибку в своем указании.
        <br/>
        Возврат(отмена) ошибочного платежа возможна в случаях, предусмотренных внутренними нормативными документами Участников Системы.
      </p>

      <p>
        15.10.3. в случае расторжения совершенной между Участником Системы - физическим лицом и Мерчантом гражданско-правовой сделки по основаниям, предусмотренным законодательством Республики
        Казахстан, в том числе, в случае отказа Участника Системы - физического лица от предмета гражданско-правовой сделки, приобретенного у Мерчанта с использованием электронных денег, и принятием
        такого отказа Мерчантом, при обязательном соблюдении следующих условий:
      </p>

      <p>
        15.10.3.1. Мерчант и физическое лицо - Участник Системы предоставляют заявления Оператору на возврат электронных денег, на бумажном носителе за подписью Участника Системы - физического лица и
        уполномоченного лица Мерчанта, с указанием причины такого возврата и идентификационного номера электронного кошелька Участника Системы - физического лица;
      </p>
      <p>
        15.10.3.2. к моменту получения от Мерчанта и Участника Системы - физического лица заявлений на возврат электронных денег, указанные электронные деньги не были погашены Эмитентом Мерчанту.
        При соблюдении данных условий и соответствия указанной Мерчантом и Участником Системы - физическим лицом в заявлениях причины возврата электронных денег основанию для возврата,
        предусмотренному
        настоящим подпунктом Правил, в Системе в течение 2 (двух) рабочих дней производится перевод электронных денег (без получения дополнительного указания от Мерчанта на такой перевод) с
        электронного
        кошелька Мерчанта на электронный кошелек Участника Системы - физического лица. При этом комиссионное вознаграждение Эмитента, ранее полученное им от Участника Системы - физического лица при
        переводе электронных денег в пользу Мерчанта, возврату не подлежит.
        <br/>
        В случае, если к моменту предоставления Мерчантом и Участником Системы - физическим лицом Оператору заявлений электронные деньги Мерчанта были погашены Эмитентом, возврат электронных денег не
        производится. В указанном случае Мерчант самостоятельно в согласованные с Участником Системы - физическим лицом сроки возмещает ему всю сумму денег, равную стоимости оплаченного Участником
        <br/>
        Системы - физическим лицом посредством ЭД товара/работы/услуги Мерчанта (без учета комиссии, уплаченной Участником Системы - физическим лицом Эмитенту при переводе, которая не подлежит
        возврату
        Эмитентом Участнику Системы - физическому лицу).
      </p>

      <p>
        15.11. В случае, предусмотренном Правилами ни Эмитент, ни Оператор, ни Агент/Субагент не несут ответственность за исполнение указания с допущенной отправителем ошибкой, а также за не возврат
        получателем электронных денег отправителю.
      </p>

      <p>
        15.12. В иных случаях, не оговоренных в Правилах, возврат электронных денег от получателя отправителю в Системе не производится, если иное не предусмотрено императивными нормами
        законодательства
        Республики Казахстан и/или страны резидентства Агента/Субагента.
      </p>

      <br/>
      <h1>16. Вывод и погашение электронных денег в Системе</h1>
      <p>16.1. Физическое лицо, Мерчант, Агент - владельцы ЭД вправе в любое время потребовать погашения ЭД у Эмитента, осуществившего эмиссию электронных денег «Wallet One».</p>
      <p>16.2. Для погашения электронных денег владельцы ЭД- физические лица должны обратиться в Банк.</p>
      <p>16.3. Владельцы ЭД - физические лица могут (по своему усмотрению) не осуществлять погашение ЭД у Эмитента, а предложить Агенту/Субагенту выкупить у них электронные деньги (вывести ЭД).</p>
      <p>16.4. Погашение Эмитентом/вывод через Агента/Субагента электронных денег осуществляется только идентифицированным в Системе физическим лицам. Неидентифицированные владельцы электронных денег
        должны пройти предварительно верификацию согласно требованиям Правил.</p>
      <p>16.5. Погашение ЭД Участников Системы - физических лиц осуществляется Эмитентом следующими способами:</p>
      <p>16.5.1. безналичный перевод денег на банковский текущий счет Участника Системы - физического лица; выдача Участнику Системы - физическому лицу наличных денег в кассе Банка.</p>
      <p>16.6. Погашение электронных денег Участника Системы - физического лица Эмитентом производится в пределах остатка ЭД на момент поступления от владельца ЭД требования о погашении. Требование о
        погашении ЭД подается владельцем ЭД следующими способами:</p>
      <p>16.6.1. при личном присутствии в Банке путем заполнения заявления физического лица на погашение ЭД по форме, установленной Эмитентом, с предоставлением в Банк документа, удостоверяющего
        личность, с индивидуальным идентификационным номером (ИИН). Банк осуществляет сверку содержащихся в Системе данных документа, удостоверяющего личность, с документом, предъявленным физическим
        лицом, а также отправляет через Систему электронное сообщение Оператору о погашении ЭД по данному заявлению Участника Системы - физического лица;</p>
      <p>16.6.2. для получения денег безналичным переводом - путем размещения в Системе через интернет-ресурс Оператора заявки на погашение с обязательным указанием суммы к погашению, ФИО и номера
        документа, удостоверяющего личность, ИИН, необходимых реквизитов банковского счета владельца электронных денег - физического лица, а также другие сведения согласно запросу Системы и
        требованиям Эмитента.</p>
      <p>16.7. При погашении электронных денег сумма выдаваемых денег владельцу ЭД, предъявившему ЭД к погашению, должна соответствовать сумме ЭД, предъявленных к погашению.</p>
      <p>16.8. Электронные деньги считаются погашенными Банком с момента зачисления соответствующей суммы денег, подлежащей передаче владельцу ЭД, на банковский счет владельца электронных денег, либо
        выдачи ему наличных денег.</p>
      <p>16.9. Выкуп ЭД Агентом/Субагентом (вывод ЭД) осуществляется согласно требованиям Агента/Субагента. Эмитент не несет ответственности за действия Агента/Субагента при выкупе ЭД у владельцев-
        физических лиц.</p>
      <p>16.10. Эмитентом и Агентом/Субагентом может взиматься комиссионное вознаграждение при погашении/выводе ЭД Участника Системы - физического лица согласно тарифам Эмитента/Агента/Субагента,
        размещенным на интернет-ресурсе Системы и/или иным способом, не противоречащим законодательству Участников Системы.</p>
      <p>16.11. Погашение Мерчантам ЭД, полученных ими за реализованные товары, оказанные услуги, работы осуществляется Эмитентом на основании информации, полученной им от Оператора из Системы, в
        течение трех рабочих дней (если иной срок не оговорен договором) путем перечисления безналичных денег на банковский счет Мерчанта.</p>
      <p>16.12. Погашение ЭД Агента производится Эмитентом на основании полученного от Агента уведомления о необходимости погашения определенной Агентом к погашению суммы ЭД, направленного в порядке,
        предусмотренном в договоре, заключенном между Агентом и Оператором. Погашение ЭД Агента производится согласно условиям заключенного между Агентом и Оператором договора безналичным платежом на
        банковский счет Агента, либо по требованию Агента путем выдачи наличных денег (в пределах ограничений, предусмотренных законодательством Республики Казахстан для платежа наличными
        деньгами)</p>

      <p>
        16.13. Электронные деньги считаются погашенными Эмитентом с момента зачисления соответствующей суммы денег, подлежащей передаче Владельцу электронных денег на банковский счет Владельца ЭД
        получателя, либо выдачи наличных денег Владельцу ЭД.
      </p>
      <p>
        16.14. Эмитент вправе требовать оплаты комиссии за рассмотрение заявления Мерчанта/Агента/Физического лица на погашение электронных денег согласно собственным тарифам.
      </p>
      <p>
        16.15. Эмитент вправе прекратить выпуск электронных денег и осуществить их принудительное гашение. Прекращение выпуска электронных денег и их принудительное гашение производится
        Оператором/Эмитентом в порядке, установленном законодательством Республики Казахстан.
      </p>

      <br/>
      <h1>17. Учет электронных денег и отчетность Эмитента перед регулирующими органами</h1>
      <p>
        17.1. Все операции по учету ЭД Эмитент отражает в соответствии с требованиями законодательства Республики Казахстан о платежах и переводах денег, Постановления Правления Национального Банка
        Республики Казахстан от 31 августа 2016 года № 202 «Об утверждении Правил выпуска, использования и погашения электронных денег, а также требований к эмитентам электронных денег и системам
        электронных денег, на территории Республики Казахстан» и внутренних нормативных документов Банка.
      </p>

      <p>
        17.2. Банк ведет отдельный учет денег, поступающих от Владельца электронных денег, на соответствующем балансовом счете, предназначенном для учета денег по операциям с электронными деньгами.
      </p>

      <p>
        17.3. Банк обеспечивает контроль за тем, чтобы сумма выпущенных им ЭД не превышала сумму наличных или безналичных денег, полученных от физических и юридических лиц для осуществления выпуска
        ЭД.
      </p>

      <br/>
      <h1>18. Взаимодействие Оператора с Мерчантами</h1>
      <p>18.1. Взаимодействие Мерчанта с Оператором осуществляется на основании заключенного между Оператором и Мерчантом договора и настоящими Правилами.</p>
      <p>
        18.2. Приобретение электронных денег/пополнение электронных кошельков Мерчантами запрещается. Мерчанты получают на свои открытые электронные кошельки электронные деньги от Участников Системы -
        физических лиц только за проданные товары/оказанные услуги, работы.
      </p>
      <p>
        18.3. Оператор по своему усмотрению вправе потребовать у Мерчанта подтверждение о доставке товаров или услуг/отпуска товара физическому лицу – Участнику Системы, которое осуществило платеж.
      </p>
      <p>
        18.4. У Мерчанта, получившего ЭД в Системе при совершении гражданско-правовых сделок, возникает право денежного требования к Эмитенту в сумме принятого платежа.
      </p>
      <p>
        18.5. Эмитент осуществляет погашение электронных денег Мерчантов безналичным платежом согласно условиям договора, заключенного между Мерчантом и Оператором.
      </p>
      <p>
        18.6. Электронные деньги, полученные Мерчантом, не подлежат погашению при их возврате физическому лицу - плательщику по основаниям, предусмотренным законодательством Республики Казахстан и
        настоящими Правилами.
      </p>
      <p>
        18.7. Эмитент и Оператор не несут ответственности за правомерность сделки Участника Системы - физического лица с Мерчантом, ее условия, а равно факт и последствия заключения, исполнения и
        расторжения сделки, в том числе за обоснованность возврата оплаты по такой сделке, а также за не возврат или несвоевременность возврата Мерчантом денег Участнику Системы - физическому лицу.
        <br/>
        Также Эмитент и Оператор не рассматривают претензии Участника Системы - физического лица, касающиеся неисполнения (ненадлежащего исполнения) Мерчантами своих обязательств по сделке, в том
        числе
        обязательств по передаче товаров, оказанию услуг или выполнению работ.
      </p>

      <br/>
      <h1>19. Порядок разрешения споров</h1>
      <p>
        19.1. Оператор и/или Эмитент приостанавливает или отказывает в проведении операций с электронными деньгами, если исполнение соответствующего указания Владельца ЭД приведет к нарушению
        требований
        законодательства Республики Казахстан, требований Правил и заключенных с Участниками Системы (агент/субагенты) договоров.
      </p>
      <p>
        19.2. О приостановке/отказе в проведении операций с ЭД Оператор незамедлительно информирует Владельца ЭД путем направления Владельцу ЭД электронного сообщения с указанием причины отказа.
      </p>

      <p>
        19.3. По всем возникающим вопросам Участник Системы - физическое лицо может обратиться в службу поддержки Оператора по контактным телефонам и электронным адресам, размещенным на страницах
        интернет-ресурса Оператора или к Эмитенту, включая круглосуточную техническую поддержку на сайте Системы Оператора.
      </p>
      <p>
        19.4. Претензии между Участниками Системы, связанные с участием в Системе и осуществлением платежа и иных операций с использованием ЭД, разрешаются в порядке, установленном законодательством
        Республики Казахстан, настоящими Правилами и договорами, заключенными между ними. Претензии Участников Системы направляются Оператору или Эмитенту согласно их обязательств перед Участниками.
      </p>
      <p>
        19.5. Порядок рассмотрения Оператором или Эмитентом претензий Участников в связи с их участием в Системе и осуществлением операций с электронными деньгами:
      </p>
      <p>
        19.5.1. Прием претензий от Участников осуществляется Оператором/Эмитентом и/или Агентом/Субагентом следующими способами:
      </p>

      <ol>
        <li>- при личном обращении в офис Оператора/Эмитента и/или Агента/Субагента и/или иные структурны подразделения (кассы) при наличии;</li>
        <li>
          - при направлении претензии в письменном виде путем почтового отправления или электронного сообщения.
        </li>
      </ol>

      <p>
        19.5.2. Претензия должна содержать следующую информацию:
      </p>

      <ol>
        <li>
          - для физического лица – имя, фамилию, отчество, ИИН, номер электронного кошелька, электронный адрес, адрес регистрации по месту жительства (либо адрес регистрации по месту пребывания, если
          он
          не совпадает с адресом местожительства), на который должен быть отправлен ответ, подпись физического лица (если претензия направлена в письменном виде), контактный телефон;
        </li>
        <li>
          - для юридического лица – наименование, БИН, юридический адрес, номер электронного кошелька, а также почтовый адрес для отправления корреспонденции, подпись руководителя или лица по
          доверенности
          (если претензия направлена в письменном виде), контактный телефон;
        </li>
        <li>
          - дату и место составления; предмет, суть, обстоятельства, подтверждающие обращение.
        </li>
      </ol>

      <p>
        19.5.3. Заявитель прилагает к претензии копии необходимых документов (платежных поручений, выписок, квитанций, чеков и др.), на которые он ссылается в претензии.
      </p>
      <p>
        19.5.4. В случае несогласия Участника с расходными операциями, проведенными по его электронному кошельку, претензии принимаются посредством отправки электронного интерактивного заявления через
        интернет-ресурс Оператора. Оператор и/или Агент/Субагент рассматривает ситуации по операциям со сроком давности не более 10 (десяти) календарных дней с даты получения заявления.
      </p>
      <p>
        19.5.5. Оператор/Эмитент и/или Агент/Субагент рассматривают претензию и отправляет на указанный заявителем адрес/электронный адрес ответ на претензию в течение 15 (пятнадцати) календарных дней
        со дня получения соответствующей претензии Участника.
      </p>
      <p>
        19.5.6. В случае если претензия была признана обоснованной, выявленные недостатки подлежат устранению Оператором/Эмитентом и/или Агентом/Субагентом (в зависимости от того, по чьей вине имел
        место тот или иной недостаток) в разумный срок.
      </p>
      <p>
        19.6. Для объективного рассмотрения спорных вопросов Участники Системы на основе взаимного согласия могут создавать экспертные комиссии с участием Оператора. Экспертные комиссии создаются для
        рассмотрения конкретного спора.
      </p>
      <p>
        19.7. О времени заседания экспертной комиссии Участники - заинтересованные стороны должны быть своевременно (не менее чем за 2 (два) рабочих дня) уведомлены Оператором.
      </p>
      <p>
        19.8. Результаты работы комиссии оформляются в форме экспертного заключения. В экспертном заключении должны быть указаны сроки выполнения Сторонами принятого решения, распределение
        обязанностей
        (при необходимости - порядок распределения между Сторонами материальных расходов), связанных с исполнением решения комиссии.
      </p>
      <p>
        19.9. В случае получения отказа в рассмотрении претензии, либо невозможности разрешения спора во внесудебном порядке, Участник имеет право обратиться в суд за защитой своих законных прав и
        интересов.
      </p>

      <br/>
      <h1>20. Обеспечение бесперебойности работы и обеспечение безопасности Системы</h1>
      <p>
        20.1. Оператор обеспечивает бесперебойное функционирование Системы электронных денег «Wallet One» в режиме 24/7/365 (24 часа в день, 7 дней в неделю, 365 дней в году), за исключением времени
        проведения профилактических работ и/иных форс-мажорных/нештатных ситуаций.
      </p>

      <p>
        20.2. Оператор обеспечивает защиту информации о средствах и методах обеспечения информационной безопасности, персональных данных и об иной информации, подлежащей обязательной защите в
        соответствии с законодательством Республики Казахстан и/или страны резидентства Агента/Субагента, которая может стать ему известной в ходе осуществления деятельности в Системе ЭД.
      </p>

      <p>
        20.3. Участники Системы обязуются принимать все необходимые меры для обеспечения безопасности и по защите информации и документов, обмен которыми осуществляется в Системе или которые доступны
        Участникам Системы в связи с использованием Системы, а также с целью выявления (предотвращения) мошенничества и противодействия легализации доходов, полученных преступным путем, и
        финансированию
        терроризма.
      </p>

      <p>
        20.4. Средства и меры предотвращения несанкционированного доступа к программно- техническим средствам, применяемые в Системе, включая программно-технические средства защиты, должны
        обеспечивать
        уровень защиты информации и сохранение ее конфиденциальности в соответствии с требованиями, установленными законодательством Республики Казахстан. Участники Системы обязуются принимать все
        необходимые меры по сохранению конфиденциальности, предотвращению несанкционированного использования и защите идентификационных данных от несанкционированного доступа со стороны третьих лиц.
      </p>

      <p>
        20.5. В случае утраты авторизационных данных Участником, Оператор, Агент/Субагент предоставляет Участнику возможность восстановления доступа к электронному кошельку путем подачи Участником
        соответствующего заявления по установленной Оператором и/или Агентом/Субагентом форме на интернет-ресурсе Оператора либо правилам Агента/Субагента. При этом, идентифицированный и/или
        неидентифицированный Владелец электронных денег – физическое лицо для восстановления доступа к электронному кошельку подает соответствующее заявление по установленной форме в любой из офисов
        или
        иных структурных подразделений/касс (при наличии) Оператора и/или Агента/Субагента, а также предоставлением доказательств владения и пользования электронным кошельком, доступ к которому
        восстанавливается (например, предоставлением перечня последних операций с использованием электронного кошелька), при этом достаточность указанных доказательств определяется по исключительному
        усмотрению Оператора и/или Агента/Субагента.
      </p>

      <br/>
      <h1>21. Конфиденциальность</h1>
      <p>
        21.1. Участники Системы обязуются соблюдать конфиденциальность в отношении не являющихся общедоступными сведений о других Участниках Системы, ставших известными Участникам Системы в связи с
        присоединением к настоящим Правилам, за исключением случаев, когда информация:
      </p>

      <ol>
        <li>
          - раскрыта по требованию или с разрешения Участника Системы, являющегося Владельцем данной информации;
        </li>
        <li>
          - подлежит предоставлению третьим лицам в объеме, необходимом для исполнения обязательств, предусмотренных настоящими Правилами;
        </li>
        <li>
          - требует раскрытия по основаниям, предусмотренным законодательством Республики Казахстан, договорными обязательствами между Участниками Системы и/или страны резидентства Участников Системы
          (Оператор, Банк-Эмитент, Мерчент, Агент, Субагент).
        </li>
      </ol>

      <p>
        21.2. Не является нарушением конфиденциальности и безопасности Участников Системы:
      </p>

      <ol>
        <li>
          - предоставление конфиденциальной информации третьей стороне в целях исполнения Правил и иных соглашений Участников Системы;
        </li>
        <li>
          - предоставление конфиденциальной информации Агентам/Субагентам в рамках заключенных договоров, в т.ч. в целях противодействия финансированию террористической деятельности и отмыванию
          преступных доходов;
        </li>
        <li>
          - предоставление конфиденциальной информации по законному требованию правоохранительных и иных уполномоченных государственных органов, а также в других предусмотренных действующим
          законодательством Республики Казахстан и/или страны резидентства Участников Системы (Оператор, Банк-Эмитент, Мерчент, Агент, Субагент) случаях.
        </li>
      </ol>

      <br/>
      <h1>22. Аутентификация Владельца электронных денег</h1>
      <p>22.1. Доступ к электронному кошельку и совершение любых операций с использованием электронного кошелька возможно исключительно после аутентификации Владельца ЭД.</p>

      <p>
        22.2. Аутентификация Владельца ЭД при доступе к электронному кошельку осуществляется программным обеспечением Системы с использованием авторизационных данных Владельца ЭД: логина, пароля,
        номера
        сотового телефона и, при необходимости, специальных SMS-сообщений.
      </p>

      <br/>
      <h1>23. Порядок внесения изменений в настоящие Правила</h1>
      <p>
        23.1. Оператор совместно с Эмитентом (по согласованию) вправе изменять и/или дополнять настоящие Правила и тарифы на пользование услугами в рамках Системы с обязательным опубликованием
        принятых
        изменений на интернет-ресурсе Системы.
      </p>

      <p>
        23.2. Изменения и/или дополнения в Правила могут вноситься как путем утверждения новой редакции Правил, так и путем подготовки текста изменений и/или дополнений к Правилам.
      </p>

      <p>
        23.3. Дата вступления в силу изменений и/или дополнений в Правила определяется Оператором по согласованию с Эмитентом (по согласованию) с учетом сроков, указанных в настоящих Правилах.
      </p>

      <p>
        23.4. Оператор уведомляет Участников о внесенных изменениях и/или дополнениях в Правила путем размещения соответствующей информации на интернет-ресурсе Оператора не менее чем за 10 (десять)
        календарных дней до вступления их в силу, если иное не предусмотрено решением Оператора/Эмитента.
      </p>

      <p>
        23.5. В случае несогласия Участника с изменениями и/или дополнениями в Правила или тарифами, Участник вправе отказаться от дальнейшего использования Системы, погасив имеющиеся у него
        электронные
        деньги и закрыв электронный кошелек в Системе.
      </p>

      <p>
        23.6. Правила, вступившие в силу, размещаются на интернет-ресурсе Оператора. Последующее внесение изменений и/или дополнений в Правила осуществляется в порядке, установленном настоящим
        разделом
        Правил.
      </p>

      <p>
        23.7. Дальнейшее использование Системы после вступления в силу любых изменений и/или дополнений в Правила означает согласие Участников с такими изменениями и/или дополнениями.
      </p>

      <br/>
      <h1>24. Порядок блокирования электронных кошельков</h1>
      <p>
        24.1. Блокирование электронных кошельков Владельцев ЭД может осуществлять Эмитент/Оператор и/или Агент/Субагент (согласно письменной договоренности). Блокирование электронного кошелька в
        безакцепном/бесспорном порядке осуществляется в случаях:
      </p>

      <ol>
        <li>
          24.1.1. получения уведомления от владельца электронных денег, в том числе при утере, краже, мошенничестве или несанкционированном использовании третьими лицами электронного кошелька;
        </li>
        <li>
          24.1.2. неисполнения владельцем электронных денег своих обязательств, предусмотренных договором, заключенным между эмитентом/оператором и владельцем электронных денег;
        </li>
        <li>
          24.1.3. совершения операций с нарушением установленных Правилами требований и условий использования электронных денег;
        </li>
        <li>
          24.1.4. наложения ареста на электронные деньги владельца электронных денег по основаниям, предусмотренным законодательными актами Республики Казахстан и/или страны резиденства
          Агента/Субагента;
        </li>
        <li>
          24.1.5. по иным основаниям, предусмотренным настоящим разделом Правил и договором, заключенным между владельцем электронных денег и Эмитентом/Оператором, в т.ч. в случах, обусловленных
          договорами между Оператором/Эмитентом и Агентом/Субагентами либо их внутренних правил.
        </li>
        <li>
          24.2. В случае подозрения на мошеннические действия Владельца кошелька/третьих лиц, при обнаружении сбоя программно-технических средств, для обеспечения безопасности Системы электронных
          денег, а также в иных случаях, указанных в Правилах, Эмитент/Агент/Субагент самостоятельно осуществляет блокирование электронного кошелька либо направляет письменный запрос Оператору на
          блокирование электронного кошелька Владельца ЭД с указанием причины, по которой необходимо заблокировать электронный кошелек. Оператор в случае получения такого запроса
          Эмитента/Агента/Субагента на блокирование электронного кошелька Владельца ЭД обязан осуществить блокирование указанного электронного кошелька.
        </li>
        <li>
          24.3. Оператор имеет право временно заблокировать доступ Владельца ЭД к кошельку или приостановить выполнение операции с ЭД в случае, если, по мнению Оператора, такая мера необходима в целях
          соблюдения законодательства Республики Казахстан и требований настоящих Правил, а также в случаях, предусмотренных условиями Оферты и (или) иных договоров, заключенных между Владельцем
          электронного кошелька и Эмитентом и/или Оператором, в т.ч. договоров между Оператором/Эмитентом и Агентом/Субагентами либо их внутренних правил.
        </li>
        <li>
          24.4. Превышение Владельцем ЭД ограничений по видам и суммам операций с электронными деньгами, установленных настоящими Правилами Системы, а также обнаружение Оператором передачи информации
          Владельцем электронного кошелька о собственных параметрах авторизации (имя пользователя, пароль) другим лицам, также влечет блокирование электронного кошелька Оператором.
        </li>
        <li>
          24.5. В случае возникновения угрозы несанкционированного доступа к электронному кошельку или хищения с него ЭД, Владелец ЭД может самостоятельно заблокировать электронный кошелек через свой
          личный кабинет в Системе, либо обратиться к Оператору/Эмитенту или Агенту/Субагенту с письменным требованием блокирования электронного кошелька.
        </li>
        <li>
          24.6. Блокирование электронного кошелька может быть осуществлено по основаниям, предусмотренным требованиями законодательства по противодействию финансирования террористической деятельности
          и отмыванию преступных доходов.
        </li>
        <li>
          24.7. Отмена блокировки электронного кошелька осуществляется Оператором/Эмитентом или Агентом/Субагентом на основании письменного заявления Владельца электронных денег, в случае устранения
          причин, по которым было осуществлено блокирование электронного кошелька или если блокирование было осуществлено Оператором на основании запроса Эмитента на блокирование электронного кошелька
          Оператор снимает блокирование только после согласования с Эмитентом.
        </li>
      </ol>

      <br/>
      <h1>25. Обмен электронными сообщениями</h1>
      <p>
        25.1. Обмен электронными сообщениями между Эмитентом, Оператором, Агентами, Субагентами и Мерчантами производится в Системе по форматам передачи информации, установленным Оператором.
        Порядок обмена электронными сообщениями в Системе определяется Оператором.
      </p>

      <p>
        25.2. Оператор разрабатывает порядок аутентификации электронных сообщений и контролирует его соблюдение.
      </p>

      <p>
        25.3. Электронные сообщения составляются на государственном и/или русском языках. Возможность использования иных языков при составлении электронных сообщений устанавливается Оператором.
      </p>

      <p>
        25.4. При формировании и передаче электронных сообщений Участники Системы соблюдают порядок защитных действий от несанкционированных платежей, установленный договорами, заключенными между
        Участниками Системы.
      </p>

      <p>
        25.5. Обмен электронными сообщениями между Эмитентом и Оператором либо Агентом/Субагентом в части направления сведений и/или пакета документов в отношении Участника Системы - физического лица,
        совершившего операцию с ЭД, осуществляется по выделенному защищенному электронному каналу связи.
      </p>

      <p>
        25.6. Эмитент, Оператор и Агенты,Субагенты обеспечивают наличие аудиторского следа в своих информационных системах по всем электронным сообщениям, поступающим и обработанным в Системе, а также
        хранение отправленных и полученных электронных сообщений в течение не менее пяти лет, если иное не установлено законодательством страны резидентства Участников Системы.
      </p>

      <br/>
      <h1>26. Порядок закрытия электронного кошелька</h1>
      <p>
        26.1. Эмитент не осуществляет закрытие электронных кошельков. Эта функция принадлежит исключительно Оператору и/или Агенту/Субагенту в рамках договоренности/заключаемых договоров.
      </p>
      <p>
        26.2. Закрытие электронного кошелька осуществляется Оператором или Агентом/Субагентом на основании заявления Владельца электронного кошелька с указанием идентификационного кода/номера
        электронного кошелька и пароля для входа в личный кабинет Системы, либо по требованию Эмитента.
      </p>
      <p>
        26.3. Закрытие Оператором или Агентом/Субагентом электронного кошелька возможно только при отсутствии на нем электронных денег либо в иных случаях, согласно внутренним правилам Оператора,
        Агента, Субагента.
      </p>
      <p>
        26.4. Условия настоящего Раздела не относятся к случаем принудительного гашения электронных денег Эмитентом в порядке, установленном законодательством Республики Казахстан.
      </p>

      <br/>
      <h1>27. Дополнительные условия</h1>

      <p>
        27.1. Система доступна для всех Участников Системы круглосуточно.
      </p>

      <p>
        27.2. Оператор предоставляет Эмитенту необходимую информацию по выпуску, использованию и погашению выпущенных Эмитентом электронных денег в порядке и сроки, установленные в договоре,
        заключенном
        между ними.
      </p>

      <p>
        27.3. Участники Системы понимают, что на остаток ЭД, имеющихся на электронных кошельках, проценты не начисляются.
      </p>

      <p>
        27.4. Участники Системы принимают на себя обязательства:
      </p>

      <ol>
        <li>
          - не осуществлять посредством платежного сервиса Системы незаконные финансовые операции, незаконную торговлю, операции по легализации (отмыванию) доходов, полученных преступным путем, и
          финансированию терроризма/экстремизма, а также любые другие операции в нарушение требований, установленных законодательством Республики Казахстан;
        </li>

        <li>
          - предотвращать попытки незаконной торговли, незаконных финансовых операций, операций, направленных на легализацию (отмывание) доходов, полученных преступным путем, и финансированию
          терроризма/экстремизма;
        </li>

        <li>
          - Владелец ЭД не вправе использовать платежный сервис Системы для совершения операций, направленных на извлечение незаконной прибыли либо сокрытие дохода. Владельцу ЭД известно об уголовной
          и
          административной ответственности за осуществление предпринимательской деятельности без регистрации или с нарушением правил регистрации, или с нарушением лицензионных требований.
        </li>
      </ol>

      <p>
        27.5. Во исполнение действующего законодательства Республики Казахстан, в частности Закона о ПФТ/ЛПД, по запросу Оператора Владелец ЭД – физическое лицо обязуется в течение 6 (шести) часов с
        момента получения такого запроса предоставить Оператору оригинал и/или нотариально заверенный документ, удостоверяющий личность физического лица, а также собственноручно заполнить и подписать
        предоставленное ему Оператором заявление на идентификацию.
      </p>

      <p>
        В случае немотивированного отказа в предоставлении Оператору сведений, указанных в настоящем пункте Правил, Оператор вправе приостановить обслуживание электронного кошелька и/или совершение
        операции с ЭД до момента исполнения указанной обязанности Владельцем ЭД – физическим лицом.
      </p>

      <p>
        27.6. Вопросы, не урегулированные настоящими Правилами, разрешаются в порядке, установленном действующим законодательством Республики Казахстан и/или условиями договоров, заключенных между
        Участниками Системы.
      </p>

      <p>
        27.7. Оператор при необходимости публикует на интернет-ресурсе в течение пятнадцати рабочих дней после их утверждения следующую информацию, определенную во внутренних документах:
      </p>

      <ol>
        <li>
          - 27.7.1. полномочия и регламент работы органа управления;
        </li>
        <li>
          - 27.7.2. полномочия высшего, исполнительного органов управления;
        </li>
        <li>
          - 27.7.3. систему управления рисками в системе электронных денег;
        </li>
        <li>
          - 27.7.4. порядок досудебного разрешения споров с участниками системы электронных денег и третьими лицами, предоставляющими услуги для функционирования системы электронных денег;
        </li>
        <li>
          - 27.7.5. критерии обеспечения эффективности работы системы электронных денег, по которым оценивается удовлетворение потребностей участников системы электронных денег качеством оказываемых
          услуг.
        </li>
      </ol>
    </div>
  </div>
</template>

<style scoped>
.userAgreementTitle {
  margin-top: 25px;
}

.cblock {
  max-width: 1160px;
}

ul {
  margin-left: 40px;
}

ul > li {
  margin-bottom: 10px;
}

ol > li {
  line-height: 1.4em;
  margin-bottom: 10px;
}

p {
  margin-top: 10px;
}
</style>