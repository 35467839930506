<script setup lang="ts">

const props = withDefaults(defineProps<{
  label?: string,
  value?: number | string | null,
  currency?: string
  note?: string,
  errorMessage?: string,
}>(), {
  note: undefined,
  errorMessage: undefined
});
</script>

<template>
  <div class="q-my-sm">

    <div class="label">
      {{ props.label }}
    </div>

    <div class="fz16">
      {{ props.value }} {{props.currency}}
    </div>

    <div v-if="props.errorMessage" class="error">{{ props.errorMessage }}</div>
    <div v-if="props.note" class="text">{{ props.note }}</div>
  </div>
</template>

<style scoped>
.label {
  font-size: 16px;
  color: var(--primary-label-color);
  margin-bottom: 5px;
}
</style>

