import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import timezonePlugin from "dayjs/plugin/timezone";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(customParseFormatPlugin);

export {default as DateTime} from "./date-time";
export {default as DateOnly} from "./date-only";
export {Timezone} from "./shared"