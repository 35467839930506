import {EndpointResponse, EndpointResponseError, ProcessHttpResponseOptions} from "./types";
import {Severity} from "../severity";

export const apiInterceptors = {
  unauthorizedCallback: async () => {
  },

  successCallback: async (response: Response): Promise<void> => {
  },

  onUnauthorized: function (callback: any) {
    this.unauthorizedCallback = callback;
  },

  onSuccess: function (callback: (response: Response) => Promise<void>) {
    this.successCallback = callback;
  },
};

export async function httpGet<TResult>(url: string, params = {}, options?: ProcessHttpResponseOptions): Promise<EndpointResponse<TResult>> {
  url += "?" + new URLSearchParams(params).toString();

  return await processHttpResponse(() => {
    return fetch(url, {
      method: "get",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      credentials: "same-origin"
    });
  }, url, options);
}

export async function httpPost<TResult>(url: string, body?: object, options?: ProcessHttpResponseOptions): Promise<EndpointResponse<TResult>> {
  return await processHttpResponse(() => fetch(url, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }), url, options);
}

export async function httpPostForm<TResult>(url: string, formData: FormData): Promise<EndpointResponse<TResult>> {
  return await processHttpResponse(() => fetch(url, {
    method: "post",
    body: formData,
    credentials: "same-origin"
  }), url);
}

function success(payload: any): EndpointResponse {
  return response(null, payload);
}

function failure(error: EndpointResponseError): EndpointResponse {
  return response(error, null);
}

function response(error: EndpointResponseError, success: any): EndpointResponse {
  return {
    error: error,
    result: success
  };
}

function systemError(errorMessage?: string): EndpointResponse {
  return failure({
    message: errorMessage ?? "Ошибка системы",
    severity: Severity.Error
  });
}


async function processHttpResponse(requestFunc: () => Promise<Response>, url: string, options?: ProcessHttpResponseOptions): Promise<EndpointResponse> {
  try {
    const result = await requestFunc();

    if (result.ok) {
      const contentType = result.headers.get("content-type");
      const isJson = contentType != null && contentType.indexOf("application/json") !== -1;

      if (url.includes("api")) {
        await apiInterceptors.successCallback(result);
      }

      if (isJson) {
        const resultJson = await result.json();
        return success(resultJson);
      }

      return success({});
    }

    if (result.status === 400) {
      const errorJson = await result.json();

      console.warn("errorJson", JSON.stringify(errorJson));

      return failure({
        ...errorJson,
        severity: Severity.Error
      });
    }

    if (result.status === 401) {
      await apiInterceptors.unauthorizedCallback();

      return failure({
        message: "Ваша сессия закончилась. Пожалуйста, авторизуйтесь снова.",
        severity: Severity.Info
      });
    }

    console.error("fetch error", JSON.stringify(result));

    return systemError(options?.errorMessage);
  } catch (e) {
    if (e instanceof TypeError) {
      return systemError(options?.errorMessage); 
    } 
    
    console.error("fetch error", e);
    
    return systemError(options?.errorMessage);
  }
}