<script setup lang="ts">
import {ref} from "vue";

import {LabelField, SiteNotifications} from "@lib/components";

import invoiceApi from "../../api/invoice-api";
import {SiteNotification} from "@lib/types";

const props = defineProps<{
  invoiceId: string,
  login: string,
  sum: number,
  comment: string,
  siteNotifications: SiteNotification[]
}>();

const emit = defineEmits(["nextClick"]);

const errorMessage = ref<string | undefined>();
const fieldErrors = ref<Record<string, string> | undefined>();

async function onNext() {
  const payRequest = {
    invoiceId: props.invoiceId!
  };

  const {error} = await invoiceApi.pay(payRequest);

  if (error) {
    errorMessage.value = error.message;
    fieldErrors.value = error.parameterErrors;
    return;
  }

  errorMessage.value = undefined;
  fieldErrors.value = undefined;

  emit("nextClick");
}
</script>

<template>
  <div class="text-left">
    <SiteNotifications :notifications="props.siteNotifications"/>

    <LabelField
        label="Номер кошелька"
        :value="props.login"
        :error-message="fieldErrors?.login"
        id="login"
    />

    <LabelField
        label="Сумма"
        :value="props.sum"
        :error-message="fieldErrors?.sum"
        id="sum"
    />

    <LabelField
        label="Коментарий"
        :value="props.comment"
        :error-message="fieldErrors?.comment"
        id="comment"
    />

    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>

    <q-btn
        @click.prevent="onNext"
        label="Оплатить"
        color="primary"
        class="q-mt-sm"
    />
  </div>
</template>
