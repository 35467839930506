<script setup lang="ts">
import {useRouter} from "vue-router";

const router = useRouter();
</script>

<template>
  <div class="footer-container">
    <div class="footer">
      <div class="footer__copyright">
        <div>W1 © {{ new Date().getFullYear() }}. Все права защищены</div>
        <div>Республика Казахстан, г. Алматы, 050040, ул. Шевченко 165 офис 517.</div>
      </div>

      <a class="footer__rules"
         href="/user-agreement"
         target="_blank"
         rel="noopener"
      >
        Правила системы электронных денег W1
      </a>
    </div>
  </div>
</template>

