import {createApp} from "vue";
import App from "./App.vue";
import {createMetaManager} from "vue-meta";
import router from "./router";
import Toast from "vue-toastification";
import {toasts} from "@lib/utils";
import {Loading, Notify, Quasar} from "quasar";
import "@quasar/extras/material-icons/material-icons.css";
import "quasar/src/css/index.sass";
import * as Sentry from "@sentry/vue";
import {CaptureConsole} from "@sentry/integrations";
import {buildVersion} from "./version";

const app = createApp(App);

//@ts-ignore
app.use(Quasar, {
  plugins: {
    Loading,
    Notify,
  },
  config: {
    loading: { /* look at QuasarConfOptions from the API card */},
    notify: {}
  }
}).use(router)
  .use(createMetaManager())
  .use(Toast, toasts.options);

Sentry.init({
  app,
  dsn: "https://99f76391f74a4e86a4d696223b2cdc1c@o4504359797784576.ingest.sentry.io/4504360178941952",
  enabled: import.meta.env.VITE_ENVIRONMENT !== "development",
  maxBreadcrumbs: 100,
  debug: false,
  release: buildVersion.buildVersion,
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    new CaptureConsole({
      levels: ["error"]
    })
  ],
});

app.mount("#app");