import {httpGet, httpPost} from "@lib/utils";
import AuthResponse from "../types/auth-response";

async function get(invoiceId: string) {
  return await httpGet<{ comment: string }>("/api/customer/invoice/v1.0/get", {invoiceId});
}

async function auth(params: {
  invoiceId: string,
  login: string,
  password: string
}) {
  return await httpPost<AuthResponse>("/api/customer/invoice/v1.0/auth", params);
}

async function pay({invoiceId}: { invoiceId: string }) {
  return await httpPost("/api/customer/invoice/v1.0/pay", {invoiceId});
}

export default {
  auth,
  get,
  pay
};